import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  CardContent,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Chip,
  Alert,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { cloneDeep, groupBy } from 'lodash';
import Loader from '../../../components/common/Loader';
import {
  ACCOUNT_NAME,
  ADDITIONAL_SELLER_FIRST_NAME,
  ADDITIONAL_SELLER_LAST_NAME,
  BUYER_BATE_OF_BIRTH,
  BUYER_DL_STATE_FIELD,
  DATE_OF_BIRTH,
  DL_AVAILABLE_TYPE,
  DL_FIELD_DOB,
  DL_FIELD_NUMBER,
  DL_FIELD_STATE,
  DL_NUMBER_FIELD,
  DL_STATE_FIELD,
  DL_VERIFICATION_FIELDS,
  DocElementType,
  ID_MAPPING,
  IS_INDIVIDUAL_ENTITY,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_QUESTION,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  SELLER_FIRST_NAME,
  SELLER_LAST_NAME,
  SOI_SECTION_ID,
  BUYER_SECTION,
  SSN_FIELDS,
  BUYER_FULL_NAME_ID,
} from '../../../utils/constants';
import {
  ErrorOutline,
  CheckCircleOutline,
  Visibility,
  ExpandMore,
  ArrowForwardIosSharp,
  VerifiedUser,
  MoreVert,
} from '@mui/icons-material';
import { getParticipant, verifyElement } from '../../../services/admin/participantForms';
import VerificationConfirmationModal from '../../../components/admin/participantForms/VerificationConfirmationModal';
import {
  convertedValue,
  fetchSourceData,
  findElement,
  matchAddress,
  compareUserValues,
  findElementByName,
} from '../../../utils/helpers';
import SourceDetailsDrawerUpdated from '../../../components/admin/participantForms/SourceDetailsDrawerUpdated';
import moment from 'moment';
import {
  downloadDocumentAdmin,
  downloadOtherDocuments,
  getDocList,
  getDocumentDetails,
} from '../../../services/questions/home';
import DocumentViewer from '../../../components/common/DocumentViewer';
import AutoCompleteModal from '../../../components/admin/participantForms/AutoCompleteModal';
import DocumentLink from './DocumentLink';
import { getOrder, getSSN, manualVerification } from '../../../services/order/order';
import { getDLDetails } from '../../../services/questions/moby';
import CommonModal from '../../../components/questions/home/CommonModal';
import { getSourceList } from '../../../services/admin/sources';
import SourceDetailsModal from '../../../components/admin/participantForms/SourceDetailsModal';
import ViewFormDetailsTable from '../../../components/order/ViewFormDetailsTable';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    className='f-14'
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#ecf3f9a3',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontWeight: '500',
  },
}));

const ViewFormDetails = () => {
  const { participantId, id } = useParams();
  const { pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const user =
    pathname.split('/')[1] === 'admin'
      ? admin?.first_name + ' ' + admin?.last_name
      : order?.client_name && !order?.parent_id
        ? order?.client_name
        : order?.first_name + ' ' + order?.last_name;
  const token = pathname.split('/')[1] === 'admin' ? admin?.token : order?.token;
  const [loading, setLoading] = useState(false);
  const [singleLoader, setSingleLoader] = useState(false);
  const [elementFetchLoading, setElementFetchLoading] = useState(false);
  const [dlLoading, setDlLoading] = useState(false);
  const [sourceLoading, setSourceLoading] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [allElements, setAllElements] = useState([]);
  const [queJson, setQueJson] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [curData, setCurData] = useState({});
  const [details, setDetails] = useState({});
  const [docs, setDocs] = useState([]);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [autoCompleteModalOpen, setAutoCompleteModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [dlConfirmationModalOpen, setDlConfirmationModalOpen] = useState(false);
  const [valueChangeModalOpen, setValueChangeModalOpen] = useState(false);
  const [allIdVerified, setAllIdVerified] = useState(true);
  const [statusData, setStatusData] = useState('');
  const [fullSSNs, setFullSSNs] = useState({});
  const [dlDetails, setDlDetails] = useState();
  const [additionalDlDetails, setAdditionalDlDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [isEntity, setIsEntity] = useState(false);
  const [sourceList, setSourceList] = useState({});
  const [dlFindings, setDlFindings] = useState({ dlErrors: {}, dlValues: {}, summary: {} });
  const [summaryDL, setSummaryDL] = useState([]);
  const [summaryBank, setSummaryBank] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [additionalParticipantMismatches, setAdditionalParticipantMismatches] = useState({});
  const [idMapping, setIdMapping] = useState({});
  const [curRecordId, setCurRecordId] = useState({});
  const summary = [...summaryDL, ...summaryBank];
  const isCompleted = details?.status === 'completed' ? true : false;
  const isBuyer = details?.user_participant_type_name?.toLowerCase().includes('buyer');
  const stateFieldID = (isBuyer ? BUYER_DL_STATE_FIELD : DL_STATE_FIELD)
  const dateFieldID = (isBuyer ? BUYER_BATE_OF_BIRTH : DATE_OF_BIRTH)
  const dlSection = (isBuyer ? BUYER_SECTION : SOI_SECTION_ID)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (queJson?.sections?.length) fetchAllElements();
  }, [queJson, dlFindings, sourceList, additionalParticipantMismatches]);

  useEffect(() => {
    fetchSourceList();
    if (participantId) fetchDetails();
  }, [participantId]);

  useEffect(() => {
    if (Object.keys(allElements || {})?.length) {
      const pendingFields = getPendingFields();
      if (!pendingFields?.length && details?.data_verify_status !== 'completed')
        setAutoCompleteModalOpen(true);
    }
  }, [allElements]);

  useEffect(() => {
    if (details?.order_participant?.order_part_participant_id) {
      getDocs(details?.order_participant?.order_part_participant_id, details?.order_participant_id);
      fetchDocuments();
    }
  }, [details]);

  useEffect(() => {
    if (details?.participant_uuid) {
      getDL();
    }
  }, [details?.participant_uuid]);

  const fetchSourceList = async () => {
    setSourceLoading(true);
    const res = await getSourceList(admin?.token);
    if (res.success) {
      setSourceList(res?.response);
    }
    setSourceLoading(false);
  };

  const getDL = async () => {
    setDlLoading(true);
    let primaryDetails = {};
    let additionalDetails = [];
    let tempIdMapping = {};
    let isAllVerified = true;
    const res = await getDLDetails(token, details?.order_participant_id);
    if (Object.keys(res?.response?.transaction_response || {})?.length) {
      primaryDetails = { ...res?.response };
      tempIdMapping[1] =
        res?.response?.transaction_response?.IdentificationTypeId &&
          ID_MAPPING?.[res?.response?.transaction_response?.IdentificationTypeId]
          ? ID_MAPPING?.[res?.response?.transaction_response?.IdentificationTypeId]
          : 'Government Issued ID';
      if (
        !res?.response?.transaction_response?.Approved &&
        !res?.response?.manual_verification_status
      )
        isAllVerified = false;
      setDlDetails(res?.response);
    }
    if (details?.single_form_additional_participants?.length) {
      let tempExtraDlDetails = [];
      for (const additionalPart of details?.single_form_additional_participants) {
        const res = await getDLDetails(token, additionalPart?.id);
        if (res?.success && Object.keys(res?.response?.transaction_response || {})?.length) {
          if (additionalPart?.participant_number) {
            tempIdMapping[additionalPart?.participant_number] =
              res?.response?.transaction_response?.IdentificationTypeId &&
                ID_MAPPING?.[res?.response?.transaction_response?.IdentificationTypeId]
                ? ID_MAPPING?.[res?.response?.transaction_response?.IdentificationTypeId]
                : 'Government Issued ID';
          }
          if (
            !res?.response?.transaction_response?.Approved &&
            !res?.response?.manual_verification_status
          )
            isAllVerified = false;
          tempExtraDlDetails.push({ ...res?.response, participant_details: additionalPart });
        }
      }
      additionalDetails = tempExtraDlDetails?.length ? tempExtraDlDetails : [];
      setAllIdVerified(isAllVerified);
      setAdditionalDlDetails(tempExtraDlDetails);
      setIdMapping(tempIdMapping);
    }
    if (Object.keys(primaryDetails || {})?.length)
      await verifyDLDetails(primaryDetails, additionalDetails);

    setDlLoading(false);
  };

  const fetchDetails = async () => {
    setLoading(true);
    const orderRes = await getOrder(id, token);
    if (orderRes.success) {
      setOrderDetails({ ...orderRes.response });
    } else {
      return toast.error(orderRes.message);
    }
    const res = await getParticipant(participantId, token);
    if (res.success) {
      let extraParts = res?.response?.additional_participants;

      // below logic is to find mismatches b/w inputs of multiple sellers
      if (extraParts?.length) {
        let payload = [
          {
            participant_name: `${res?.response?.full_name}`,
            json: res.response?.cached_questions_json?.question,
            main: true,
          },
        ];
        extraParts?.forEach((part) => {
          if (part?.cached_questions_json?.question && part?.form_status === 'completed') {
            payload?.push({
              participant_name: `${part?.first_name} ${part?.last_name}`,
              json: part?.cached_questions_json?.question,
              main: false,
            });
          }
        });
        if (payload?.length > 1) {
          const mismatches = compareUserValues(payload);
          if (Object?.keys(mismatches || {})?.length) {
            setAdditionalParticipantMismatches(mismatches);
          }
        }
      }
      setDetails({ ...res.response });
      setQueJson(res.response?.cached_questions_json?.question);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const getDocs = async (order_part_participant_id, part_id) => {
    setDocLoading(true);
    const res = await getDocList(order_part_participant_id, part_id, token, true);
    if (Object.keys(res || {})?.length) {
      let tempDocs = [];
      for (const key in res) {
        tempDocs.push({
          name: res?.[key]?.doc_type,
          label: res?.[key]?.doc_name,
          participant_number: res?.[key]?.participant_number,
        });
      }
      setAdditionalDocs(tempDocs);
    }
    setDocLoading(false);
  };

  const fetchDocuments = async () => {
    setDocLoading(true);
    const res = await getDocumentDetails(
      {
        order_id: id,
        order_part_participant_id: details?.order_participant?.order_part_participant_id,
      },
      token
    );
    if (res.success) {
      setDocs(res.response.files || []);
    }
    setDocLoading(false);
  };
  const getUserAddress = (section) => {
    let userAddress = {};
    const currentOrOtherAddress = findElement(section, MAILING_ADDRESS_QUESTION)?.user_value || '';
    if (!currentOrOtherAddress) return userAddress;
    if (currentOrOtherAddress === 'Other') {
      const address1 = findElement(section, MAILING_ADDRESS_ADDRESS)?.user_value || '';
      const state = findElement(section, MAILING_ADDRESS_STATE)?.user_value || '';
      const city = findElement(section, MAILING_ADDRESS_CITY)?.user_value || '';
      const zip = findElement(section, MAILING_ADDRESS_ZIP)?.user_value || '';
      userAddress = {
        site_address: address1,
        site_city: city,
        site_state: state,
        site_zip: `${zip}`,
        // site_unit: unit,
        site_full_address: address1 + ',' + city + ',' + state + ',' + zip,
      };
    } else {
      userAddress = {
        site_address: orderDetails?.property_address_1,
        site_city: orderDetails?.property_city,
        site_state: orderDetails?.property_state,
        site_zip: `${orderDetails?.property_zipcode}`,
        // site_unit: unit,
        site_full_address:
          orderDetails?.property_address_1 +
          ',' +
          orderDetails?.property_city +
          ',' +
          orderDetails?.property_state +
          ',' +
          orderDetails?.property_zipcode,
      };
    }
    return { userAddress, addressType: currentOrOtherAddress };
  };

  const verifyDLDetails = async (dlData, additionalDlData = []) => {
    const idNameFinder = (dlInfo) =>
      dlInfo?.transaction_response?.IdentificationTypeId &&
        ID_MAPPING?.[dlInfo?.transaction_response?.IdentificationTypeId]
        ? ID_MAPPING?.[dlInfo?.transaction_response?.IdentificationTypeId]
        : 'Government Issued ID';

    let dlErrors = {};
    let dlValues = {};
    let summary = [];
    const section = queJson.sections.find((section) => section.section_id === dlSection);
    const userAddr = getUserAddress(section)?.userAddress;
    if (dlData?.transaction_response?.CustomerAddress) {
      dlErrors = await matchAddress(dlData?.transaction_response?.CustomerAddress, userAddr, token);
    }

    // need to refactor the logic below and create a common function to match information for both primary and additional participants.

    dlErrors[MAILING_ADDRESS_QUESTION] = dlErrors?.[MAILING_ADDRESS_ADDRESS];
    const firstName = findElement(section, SELLER_FIRST_NAME)?.user_value || '';
    const lastName = findElement(section, SELLER_LAST_NAME)?.user_value || '';
    const idNumber = findElement(section, DL_NUMBER_FIELD)?.user_value || '';
    const idState = findElement(section, stateFieldID)?.user_value || '';
    const dob = findElement(section, dateFieldID)?.user_value || '';

    // Buyer form contains full name field therefore different comparison for buyer form
    const buyerFullName = findElement(section, BUYER_FULL_NAME_ID)?.user_value || '';
    const isError = (val1, val2) => val1 && val2 && val1?.toLowerCase() !== val2?.toLowerCase();
    dlErrors = {
      ...dlErrors,
      [SELLER_FIRST_NAME]: isError(firstName, dlData?.transaction_response?.FirstName),
      [SELLER_LAST_NAME]: isError(lastName, dlData?.transaction_response?.FirstSurname),

      // Buyer form contains full name field therefore different comparison for buyer form
      [BUYER_FULL_NAME_ID]: isError(buyerFullName, dlData?.transaction_response?.FirstName + " " + dlData?.transaction_response?.FirstSurname),
      [DL_NUMBER_FIELD]: isError(idNumber, dlData?.transaction_response?.IdentificationNumber),
      [stateFieldID]: isError(idState, dlErrors?.state),
      [dateFieldID]: isError(
        dob,
        dlData?.transaction_response?.BirthDate
          ? moment(dlData?.transaction_response?.BirthDate, moment.ISO_8601, true).isValid()
            ? moment(dlData?.transaction_response?.BirthDate).format('MM/DD/YYYY')
            : ''
          : ''
      ),
    };

    // data for form summary
    if (!isBuyer && dlErrors?.[MAILING_ADDRESS_ADDRESS]) {
      summary.push({
        message: `Participant(${details?.order_participant?.full_name || ''
          })'s <i>Mailing Address</i> does not match with the address on Government Issued ID`,
        color: 'orange',
      });
    }
    if (dlErrors[SELLER_FIRST_NAME] || dlErrors[SELLER_LAST_NAME])
      summary.push({
        message: `Participant(${details?.order_participant?.full_name || ''
          })'s <i>Name</i> does not match with the name on uploaded ${idNameFinder(dlData)}`,
        color: 'orange',
      });

    // Buyer form contains full name field therefore different comparison for buyer form
    if (isBuyer && dlErrors[BUYER_FULL_NAME_ID])
      summary.push({
        message: `Participant(${details?.order_participant?.full_name || ''
          })'s <i>Name</i> does not match with the name on uploaded ${idNameFinder(dlData)}`,
        color: 'orange',
      });
    if (dlErrors[DL_NUMBER_FIELD])
      summary.push({
        message: `Participant(${details?.order_participant?.full_name || ''})'s <i>${idNameFinder(
          dlData
        )} number</i> does not match with uploaded ${idNameFinder(dlData)}'s number.`,
        color: 'orange',
      });
    if (dlErrors[stateFieldID])
      summary.push({
        message: `Participant(${details?.order_participant?.full_name || ''})'s <i>${idNameFinder(
          dlData
        )} state</i> does not match with uploaded ${idNameFinder(dlData)}'s state.`,
        color: 'orange',
      });
    if (dlErrors[dateFieldID])
      summary.push({
        message: `Participant(${details?.order_participant?.full_name || ''
          })'s input for Birth Date does not match with Birth Date on uploaded ${idNameFinder(
            dlData
          )}.`,
        color: 'orange',
      });
    dlValues = {
      [MAILING_ADDRESS_QUESTION]: dlErrors?.moby_address?.site_full_address ?? '',
      [SELLER_FIRST_NAME]: dlData?.transaction_response?.FirstName ?? '',
      [SELLER_LAST_NAME]: dlData?.transaction_response?.FirstSurname ?? '',
      [BUYER_FULL_NAME_ID]: dlData?.transaction_response?.FirstName + " " + dlData?.transaction_response?.FirstSurname,
      [DL_NUMBER_FIELD]: dlData?.transaction_response?.IdentificationNumber ?? '',
      [stateFieldID]: dlErrors?.state ?? '',
      [MAILING_ADDRESS_ADDRESS]: dlErrors?.moby_address?.site_address ?? '',
      [MAILING_ADDRESS_STATE]: dlErrors?.moby_address?.site_state ?? '',
      [MAILING_ADDRESS_CITY]: dlErrors?.moby_address?.site_city ?? '',
      [MAILING_ADDRESS_ZIP]: dlErrors?.moby_address?.site_zip ?? '',
      [dateFieldID]: dlData?.transaction_response?.BirthDate
        ? moment(dlData?.transaction_response?.BirthDate, moment.ISO_8601, true).isValid()
          ? moment(dlData?.transaction_response?.BirthDate).format('MM/DD/YYYY')
          : ''
        : '',
    };

    // need to refactor the logic below and create a common function to match information for both primary and additional participants.

    if (additionalDlData?.length) {
      for (const addPartData of additionalDlData) {
        const firstName = findElementByName(
          section,
          details?.user_participant_type_name?.toLowerCase() +
          ADDITIONAL_SELLER_FIRST_NAME +
          `_${addPartData?.participant_details?.participant_number}`
        );
        const lastName = findElementByName(
          section,
          details?.user_participant_type_name?.toLowerCase() +
          ADDITIONAL_SELLER_LAST_NAME +
          `_${addPartData?.participant_details?.participant_number}`
        );
        const idNumber = findElementByName(
          section,
          details?.user_participant_type_name?.toLowerCase() +
          DL_FIELD_NUMBER +
          `_${addPartData?.participant_details?.participant_number}`
        );
        const idState = findElementByName(
          section,
          details?.user_participant_type_name?.toLowerCase() +
          DL_FIELD_STATE +
          `_${addPartData?.participant_details?.participant_number}`
        );
        const dob = findElementByName(
          section,
          details?.user_participant_type_name?.toLowerCase() +
          DL_FIELD_DOB +
          `_${addPartData?.participant_details?.participant_number}`
        );
        const addressData = await matchAddress(
          addPartData?.transaction_response?.CustomerAddress,
          {},
          token
        );
        dlErrors = {
          ...dlErrors,
          [firstName?.element_id]: isError(
            firstName?.user_value || '',
            isBuyer ? addPartData?.transaction_response?.FirstName + " " + addPartData?.transaction_response?.FirstSurname : addPartData?.transaction_response?.FirstName
          ),
          [lastName?.element_id]: isBuyer ? undefined : isError(
            lastName?.user_value || '',
            addPartData?.transaction_response?.FirstSurname
          ),
          [idNumber?.element_id]: isError(
            idNumber?.user_value || '',
            addPartData?.transaction_response?.IdentificationNumber
          ),
          [idState?.element_id]: isError(idState?.user_value || '', addressData?.state),
          [dob?.element_id]: isError(
            dob?.user_value || '',
            addPartData?.transaction_response?.BirthDate
              ? moment(
                addPartData?.transaction_response?.BirthDate,
                moment.ISO_8601,
                true
              ).isValid()
                ? moment(addPartData?.transaction_response?.BirthDate).format('MM/DD/YYYY')
                : ''
              : ''
          ),
        };

        if (dlErrors[firstName?.element_id] || dlErrors[lastName?.element_id])
          summary.push({
            message: `Additional Participant(${addPartData?.participant_details?.full_name || ''
              })'s <i>Name</i> does not match with the name on uploaded ${idNameFinder(addPartData)}`,
            color: 'orange',
          });
        if (dlErrors[idNumber?.element_id])
          summary.push({
            message: `Additional Participant(${addPartData?.participant_details?.full_name || ''
              })'s <i>${idNameFinder(
                addPartData
              )} number</i> does not match with uploaded ${idNameFinder(addPartData)}'s number.`,
            color: 'orange',
          });
        if (dlErrors[dob?.element_id])
          summary.push({
            message: `Additional Participant(${addPartData?.participant_details?.full_name || ''
              })'s input for Birth Date does not match with Birth Date on uploaded ${idNameFinder(
                addPartData
              )}.`,
            color: 'orange',
          });
        if (dlErrors[idState?.element_id])
          summary.push({
            message: `Additional Participant(${addPartData?.participant_details?.full_name || ''
              })'s input for Government Issued ID State does not match with state on uploaded ${idNameFinder(
                addPartData
              )}.`,
            color: 'orange',
          });
        dlValues = {
          ...dlValues,
          [firstName?.element_id]: isBuyer ? addPartData?.transaction_response?.FirstName + " " + addPartData?.transaction_response?.FirstSurname : addPartData?.transaction_response?.FirstName || "",
          [lastName?.element_id]: addPartData?.transaction_response?.FirstSurname ?? '',
          [idNumber?.element_id]: addPartData?.transaction_response?.IdentificationNumber ?? '',
          [idState?.element_id]: addressData?.state ?? '',
          [dob?.element_id]: addPartData?.transaction_response?.BirthDate
            ? moment(addPartData?.transaction_response?.BirthDate, moment.ISO_8601, true).isValid()
              ? moment(addPartData?.transaction_response?.BirthDate).format('MM/DD/YYYY')
              : ''
            : '',
        };
      }
    }
    setDlFindings({ dlErrors, dlValues, summary });
  };
  const fetchAllElements = async () => {
    try {
      setElementFetchLoading(true);
      let elementsData = cloneDeep(queJson);
      let dlErrors = {};
      let dlValues = {};
      // let bankAccountVerified = false;

      let tempAdditionalDlSum = [];
      // Additional participant dl summary
      // If new participant type has DL upload feature then need to add that participant type name in this constant
      if (DL_AVAILABLE_TYPE?.includes(details?.user_participant_type_name)) {
        for (const addPartDetails of additionalDlDetails) {
          tempAdditionalDlSum.push({
            message: `Participant(${addPartDetails?.participant_details?.full_name || ''})'s <i>${idMapping[addPartDetails?.participant_details?.participant_number || 1] ||
              'Government Issued ID'
              }</i> is ${addPartDetails?.transaction_response?.Approved ? 'verified' : 'not verified'
              }.`,
            color: addPartDetails?.transaction_response?.Approved ? 'green' : 'orange',
          });
        }
        // DL validations
        if (
          Object.keys(dlDetails?.transaction_response || {})?.length &&
          dlDetails?.transaction_response?.Approved
        ) {
          dlErrors = dlFindings?.dlErrors || {};
          dlValues = dlFindings?.dlValues || {};
          setSummaryDL([
            {
              message: `Participant(${details?.order_participant?.full_name || ''})'s <i>${idMapping[1] || 'Goverment Issued ID'
                }</i> is verified.`,
              color: 'green',
            },
            ...tempAdditionalDlSum,
            ...dlFindings?.summary,
          ]);
        } else {
          setSummaryDL([
            {
              message: `Participant(${details?.order_participant?.full_name || ''})'s <i>${idMapping[1] || 'Goverment Issued ID'
                }</i> is not verified.`,
              color: 'red',
              dlError: true,
            },
            ...tempAdditionalDlSum,
          ]);
        }
      }
      let tempElements = [];

      const answer = async (
        element,
        sectionName = '',
        secIdx,
        grpIdx,
        grpLabel,
        participantNo = 1
      ) => {
        if (
          ![
            DocElementType.Label,
            DocElementType.File,
            DocElementType.Media,
            DocElementType.MediaLink,
            DocElementType.Upload,
            DocElementType.DocumentLink,
          ].includes(element.element_type) &&
          element?.display
        ) {
          let sources = fetchSourceData(element);
          const tempSource = sources?.find((src) => src.is_verified);
          let verifiedSource = tempSource?.source ?? tempSource?.source;
          let txError = null;
          const tx = sources.find((src) => src.source === 'tx');
          let isVerifiedWithSource = element?.is_verified_with_source;
          let sourceError = false;

          if (element.element_id === IS_INDIVIDUAL_ENTITY && element.user_value === 'Entity') {
            setSummaryDL((prev) => prev.filter((row) => !row?.dlError));
            setIsEntity(true);
          }

          // verification for DL related fields that user has changed

          if (DL_VERIFICATION_FIELDS.includes(element.element_id)) {
            if (isCompleted && tx && !verifiedSource) {
              txError = `The participant has changed the value for the field. Initially, the value was <b>${tx.value}</b> when the order was created.`;
            }
          }

          // verification for DL related fields to check if user input matches with DL response

          if (dlErrors?.[element.element_id] && dlValues?.[element.element_id]?.length) {
            if (!element?.verified_by_user_id) {
              isVerifiedWithSource = false;
              sourceError = true;
            }

            txError = `The participant's input value for the field differs from the ${idMapping[element?.participant_no || element?.participant_number || 1] ||
              'Government Issued ID'
              }. The value on the ${idMapping[element?.participant_no || element?.participant_number || 1] ||
              'Government Issued ID'
              } is <b>${dlValues?.[element.element_id]}</b>.`;
          }

          if (
            element.element_id === MAILING_ADDRESS_ADDRESS &&
            dlErrors?.[MAILING_ADDRESS_ADDRESS] &&
            !dlErrors?.[MAILING_ADDRESS_ZIP] &&
            dlErrors?.is_multiple
          ) {
            txError = `Multiple addresses were found for this mailing address. There might be an issue with the unit number.`;
          }
          if (
            Object.keys(dlDetails?.transaction_response || {})?.length &&
            dlErrors[element.element_id] === false
          ) {
            isVerifiedWithSource = true;
            verifiedSource =
              idMapping[element?.participant_no || element?.participant_number || 1] ||
              'Government Issued ID';
          }

          if (dlValues?.[element.element_id]) {
            sources.push({
              source:
                idMapping[element?.participant_no || element?.participant_number || 1] ||
                'Government Issued ID',
              value: dlValues?.[element.element_id],
              is_verified: !dlErrors?.[element.element_id],
              is_from_benutech: false,
            });
          }
          // bank field verification
          if (element?.value_from_bank_source) {
            if (element?.value_from_bank_source === 'no') {
              if (element.label.replace(':', '') === ACCOUNT_NAME) {
                setSummaryBank((prev) => [
                  { message: `Participant's <i>Bank Account</i> is not verified.`, color: 'red' },
                ]);
              }
              txError = `Participant's bank account is not verified.`;
              if (!element?.verified_by_user_id) {
                isVerifiedWithSource = false;
                sourceError = true;
              }
            } else if (element?.value_from_bank_source != element.user_value) {
              txError = `Participant's input for this field differ from verified bank account.`;
              if (!element?.verified_by_user_id) {
                isVerifiedWithSource = false;
                sourceError = true;
              }
            } else {
              if (element.label.replace(':', '') === ACCOUNT_NAME) {
                setSummaryBank((prev) => [
                  { message: `Participant's <i>Bank Account</i> is verified.`, color: 'green' },
                ]);
              }
              isVerifiedWithSource = true;
              verifiedSource = 'Lyons';
              sources.push({
                source: 'Lyons',
                value: element?.value_from_bank_source,
                is_verified: true,
                is_from_benutech: false,
              });
            }
          }

          // Additional sellers mismatches
          if (
            additionalParticipantMismatches?.[element?.element_id] &&
            !element?.verified_by_user_id
          ) {
            sourceError = true;
            txError = `Another ${details?.user_participant_type_name ? details?.user_participant_type_name : ''
              } <b>(${additionalParticipantMismatches?.[element?.element_id]
                ?.map((seller) => seller?.participant_name)
                .join(', ')})</b> has added different value <b>(${additionalParticipantMismatches?.[
                  element?.element_id
                ]
                  ?.map((seller) => seller?.value ?? 'NULL')
                  .join(', ')})</b> for this field${additionalParticipantMismatches?.[element?.element_id]?.length > 1
                    ? ' respectively'
                    : ''
              }`;
          }

          tempElements.push({
            label: element.label,
            element_id: element.element_id,
            element_name: element.element_name,
            participant_number: element.participant_number,
            section_name: sectionName,
            verification: element.verification,
            element_type: element.element_type,
            rejected_by: element?.rejected_by,
            ssn_ein_masked_number: element?.ssn_ein_masked_number
              ? element.ssn_ein_masked_number
              : '',
            user_value:
              element.element_type === DocElementType.Checkbox
                ? convertedValue(element.user_value)
                : element.user_value,
            required: element?.required,
            note: element?.note,
            is_verified_with_source: isVerifiedWithSource ? isVerifiedWithSource : false,
            verified_by_user_id: element?.verified_by_user_id ? element?.verified_by_user_id : '',
            ssn_part_1: element?.ssn_part_1 ? element.ssn_part_1 : '',
            ssn_part_2: element?.ssn_part_2 ? element.ssn_part_2 : '',
            tx_error: txError ? txError : '',
            participantNo,
            history: element?.history || [],
            sources: sources,
            secIdx,
            verifiedSource: sourceList[verifiedSource]
              ? sourceList[verifiedSource]
              : verifiedSource,
            grpIdx,
            status:
              details?.data_verify_status === 'completed'
                ? 'Verified'
                : sourceError
                  ? 'Pending'
                  : isVerifiedWithSource || element?.verified_by_user_id
                    ? 'Verified'
                    : 'Unknown',
            grpLabel,
          });
        }

        if (element.options.length) {
          for (const option of element.options) {
            if (element?.user_value === option.option_name) {
              for (const subElement of option.elements) {
                await answer(subElement, sectionName, secIdx, grpIdx, grpLabel, participantNo);
              }
            }
          }
        }
      };
      for (const [secIdx, section] of elementsData?.sections?.entries()) {
        if (section?.is_hidden) continue;
        for (const [grpIdx, group] of section?.groups?.entries()) {
          if (group.is_hidden) continue;
          for (const element of group.elements) {
            await answer(
              element,
              section.section_label,
              secIdx,
              grpIdx,
              group?.group_label,
              group?.participant_no || 1
            );
          }
        }
      }
      const groupedElements = groupBy(tempElements, 'section_name');
      setAllElements(groupedElements);
      setElementFetchLoading(false);
    } catch (e) {
      setElementFetchLoading(false);
    }
  };

  const onVerify = async (data, isAll = false) => {
    setSingleLoader(true);
    let tempJson = cloneDeep(queJson);
    if (isAll) {
      const answer = async (element) => {
        if (!element?.is_verified_with_source) {
          element.verified_by_user_id = admin?.user_id || order?.user_id;
          element.rejected_by = null;
          element.history = element?.history?.length
            ? [
              ...element.history,
              {
                note: '-',
                created_by: user,
                approval: 1,
                created_at: moment(new Date()).format('YYYY-MM-DD'),
              },
            ]
            : [
              {
                note: '-',
                created_by: user,
                approval: 1,
                created_at: moment(new Date()).format('YYYY-MM-DD'),
              },
            ];
        }
        if (element.options.length) {
          for (const option of element.options) {
            if (element?.user_value === option.option_name) {
              for (const subElement of option.elements) {
                await answer(subElement);
              }
            }
          }
        }
      };
      for (const section of tempJson.sections) {
        for (const grp of section.groups) {
          for (const element of grp.elements) {
            await answer(element);
          }
        }
      }
    } else {
      for (let field of data) {
        let elementFound = false;
        const answer = async (element) => {
          if (element.element_id === field.element_id) {
            element.verified_by_user_id = admin?.user_id || order?.user_id;
            element.rejected_by = null;
            element.user_value = field.user_value;
            element.history = field.history;
            element.note = field.note;
            elementFound = true;
            return;
          }
          if (element.options.length) {
            for (const option of element.options) {
              if (element?.user_value === option.option_name) {
                for (const subElement of option.elements) {
                  await answer(subElement);
                }
              }
            }
          }
        };
        let currentGroup = tempJson?.sections[field?.secIdx]?.groups[field?.grpIdx];
        for (const element of currentGroup.elements) {
          if (elementFound) {
            break;
          }
          await answer(element);
        }
      }
    }

    const res = await verifyElement(
      details?.participant_uuid,
      {
        cached_questions_json: {
          question: cloneDeep(tempJson),
        },
        is_completed: isAll,
      },
      token
    );
    if (!res?.success) {
      toast.error(res.message);
    }
    setValueChangeModalOpen(false);
    await fetchDetails();
    setSelectedFields([]);
    setOpen(false);
    setVerificationModalOpen(false);
    setSingleLoader(false);
  };

  const onUnverify = async (data) => {
    setLoading(true);
    let tempJson = cloneDeep(queJson);
    for (let field of data) {
      let elementFound = false;
      const answer = async (element) => {
        if (element.element_id === field.element_id) {
          element.verified_by_user_id = null;
          element.is_verified_with_source = null;
          element.rejected_by = admin?.user_id || order?.user_id;
          element.history = field.history;
          elementFound = true;
          return;
        }
        if (element.options.length) {
          for (const option of element.options) {
            if (element?.user_value === option.option_name) {
              for (const subElement of option.elements) {
                await answer(subElement);
              }
            }
          }
        }
      };
      let currentGroup = tempJson?.sections[field?.secIdx]?.groups[field?.grpIdx];
      for (const element of currentGroup.elements) {
        if (elementFound) {
          break;
        }
        await answer(element);
      }
    }

    const res = await verifyElement(
      details?.participant_uuid,
      {
        cached_questions_json: {
          question: cloneDeep(tempJson),
        },
        is_completed: false,
      },
      token
    );
    if (!res?.success) {
      toast.error(res.message);
    }
    await fetchDetails();
    setSelectedFields([]);
    setOpen(false);
    setVerificationModalOpen(false);
    setLoading(false);
  };

  const onSelect = (element) => {
    setSelectedFields((fields) => {
      let tempElements = [...fields];
      let foundedIndex = tempElements?.findIndex(
        (field) => field.element_id === element.element_id
      );
      if (foundedIndex === -1) {
        let tempElem = cloneDeep(element);
        tempElem.history = tempElem?.history?.length
          ? [
            ...tempElem.history,
            {
              note: '-',
              created_by: user,
              approval: 1,
              created_at: moment(new Date()).format('YYYY-MM-DD'),
            },
          ]
          : [
            {
              note: '-',
              created_by: user,
              approval: 1,
              created_at: moment(new Date()).format('YYYY-MM-DD'),
            },
          ];
        return [...tempElements, tempElem];
      } else {
        tempElements.splice(foundedIndex, 1);
        return tempElements;
      }
    });
  };

  const downloadAdditionalDocs = async (elemName, participantNum) => {
    setSingleLoader(true);
    if (!isCompleted) {
      toast.error(
        `Documents will be accessible exclusively upon the participant's completion of the form.`
      );
      return setSingleLoader(false);
    }
    let url = '';
    if (elemName === 'core_lender_last_deed_1') {
      const res = await getDocumentDetails(
        {
          order_id: id,
          form_element_name: elemName,
        },
        token
      );
      if (res?.success && res?.response?.files[0]?.url) url = res?.response?.files[0]?.url;
    } else {
      const res = await downloadOtherDocuments(
        details?.participant_uuid,
        token,
        elemName,
        participantNum ? participantNum : 1
      );
      if (res?.success && res?.response?.file) url = res?.response?.file || '';
    }
    if (url) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            setSingleLoader(false);
            toast.error('Document is not available');
          }
          return response.blob();
        })
        .then((blobData) => {
          setSingleLoader(false);
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(url);
          setDocModalOpen(true);
        })
        .catch((error) => {
          setSingleLoader(false);
        });
    } else {
      setSingleLoader(false);
      toast.error('Document is not available');
    }
  };
  const getFullSSN = async (element) => {
    setSingleLoader(true);
    const isAdditionalParticipant =
      (element?.participant_number && element?.participant_number !== 1) ||
        element.element_name === 'core_spouse_ssn' ||
        element.element_name === 'borrower_spouse_ssn'
        ? true
        : false;
    let payload = { show_number: true };
    if (isAdditionalParticipant) {
      if (!element?.ssn_part_1?.length || !element?.ssn_part_2?.length)
        return setSingleLoader(false);
      payload.ssn_part_1 = element?.ssn_part_1;
      payload.ssn_part_2 = element?.ssn_part_2;
    } else {
      payload.order_participant_id = details?.order_participant_id;
    }
    const res = await getSSN(payload, order?.token);
    if (res.success) {
      setFullSSNs((prev) => {
        return { ...prev, [element.element_name]: res?.response?.full_number };
      });
    }
    // if (allElements?.[section]?.[index]?.user_value?.length) {
    //   setFullSSNs((prev) => {
    //     return {
    //       ...prev,
    //       [elem_name]: allElements?.[section]?.[index]?.user_value,
    //     };
    //   });
    // } else {
    //   toast.error('Value not available.');
    // }
    setSingleLoader(false);
  };

  const statementPdf = async () => {
    setSingleLoader(true);
    if (!isCompleted) {
      toast.error(
        `Documents will be accessible exclusively upon the participant's completion of the form.`
      );
      return setSingleLoader(false);
    }
    const urlData = await downloadDocumentAdmin(
      details?.participant_uuid,
      details?.order_participant_id,
      token
    );
    if (urlData?.success) {
      fetch(urlData?.response?.file)
        .then((response) => {
          return response.blob();
        })
        .then((blobData) => {
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(urlData?.response?.file);
          setDocModalOpen(true);
        });
    } else {
      toast.error(urlData.message);
    }
    setSingleLoader(false);
  };

  const getExtraDoc = (url) => {
    if (!isCompleted) {
      toast.error(
        `Documents will be accessible exclusively upon the participant's completion of the form.`
      );
      return;
    }
    setSingleLoader(true);
    if (url) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            setSingleLoader(false);
            toast.error('Document is not available');
          }
          return response.blob();
        })
        .then((blobData) => {
          setSingleLoader(false);
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(url);
          setDocModalOpen(true);
        })
        .catch((error) => {
          setSingleLoader(false);
        });
    } else {
      setSingleLoader(false);
      toast.error('Document is not available');
    }
  };

  const getPendingFields = () => {
    let tempElements = [];
    let tempAllElements = cloneDeep(allElements);
    for (const section of Object.keys(tempAllElements)) {
      tempElements.push(...tempAllElements[section]);
    }
    tempElements = tempElements.filter(
      (element) => !element.verified_by_user_id && !element.is_verified_with_source
    );
    return tempElements;
  };

  const verifyMultiple = (isAll) => {
    if (isAll) {
      let tempElements = getPendingFields();
      setIsAllSelected(true);
      setSelectedFields(tempElements);
    } else {
      setIsAllSelected(false);
    }
    setVerificationModalOpen(true);
  };

  const onAutoCompleteVerification = async () => {
    setLoading(true);
    const res = await verifyElement(
      details?.participant_uuid,
      {
        cached_questions_json: {
          question: cloneDeep(queJson),
        },
        is_completed: true,
      },
      token
    );
    if (res?.success) {
      toast.success(res.message);
      await fetchDetails();
    } else {
      toast.error(res.message);
    }
    setAutoCompleteModalOpen(false);
    setLoading(false);
  };

  const handleVerifyRejectDL = async () => {
    setDlLoading(true);
    const res = await manualVerification(
      {
        participant_uuid: details?.participant_uuid,
        manual_status: statusData,
        order_participant_id: curRecordId,
      },
      token
    );

    if (res?.success) {
      toast.success(res?.message);
      setDlConfirmationModalOpen(false);
      await getDL();
    } else {
      toast.error(res.message);
    }
    setDlLoading(false);
  };

  let columns = [
    { label: 'View', id: 'view' },
    {
      label: 'Label',
      id: 'label',
      render: () => <div style={{ width: '220px' }}>Form Field Label</div>,
    },
    {
      label: 'Value',
      id: 'value',
      type: 'jsx',
      render: () => <div style={{ width: '220px' }}>Participant's Input</div>,
    },
    {
      label: 'Status',
      id: 'status',
      render: () => <div style={{ width: '100px' }}>Status</div>,
    },
    {
      label: 'Verified With',
      id: 'source',
      render: () => <div style={{ width: '150px' }}>Verified With</div>,
    },
    {
      label: 'Additional Note',
      id: 'note',
      render: () => <div style={{ width: '250px' }}>Additional Note</div>,
    },
  ];

  const handleBlur = (elem) => {
    if (
      formValues?.[elem?.element_id] &&
      formValues[elem?.element_id]?.trim(' ') !== elem?.user_value
    ) {
      setValueChangeModalOpen(true);
      setCurData({ ...elem, updated_user_value: formValues?.[elem?.element_id] });
      setFormValues((prev) => {
        let tempData = { ...prev };
        delete tempData?.[elem?.element_id];
        return tempData;
      });
    }
  };
  const getTableData = (data) => {
    const tempData = data?.map((element) => {
      return {
        label: element?.label,
        groupLabel:
          element?.grpLabel +
          `${element?.participantNo && +element?.participantNo !== 1
            ? ` (Additional Participant ${element?.participantNo})`
            : ''
          }`,
        value: () => (
          <div className='d-flex participant-input-field'>
            <span>
              <TextField
                style={{ height: '30px' }}
                className={`${formValues[element?.element_id] &&
                  formValues[element?.element_id]?.trim(' ') !== element?.user_value
                  ? 'updated-field'
                  : ''
                  }`}
                value={
                  SSN_FIELDS.find((field) => element?.element_name?.startsWith(field))
                    ? fullSSNs?.[element?.element_name]
                      ? fullSSNs?.[element?.element_name]
                      : element?.ssn_ein_masked_number
                    : formValues[element?.element_id] || element?.user_value
                }
                onChange={(e) => {
                  setFormValues((prev) => {
                    return { ...prev, [element?.element_id]: e.target.value };
                  });
                }}
                disabled={
                  !isCompleted ||
                  SSN_FIELDS.find((field) => element?.element_name?.startsWith(field))
                }
                onBlur={() => {
                  handleBlur(element);
                }}
              />
            </span>
            <span>
              {' '}
              {SSN_FIELDS.find((field) => element.element_name?.startsWith(field)) &&
                !fullSSNs?.[element?.element_name] && element?.ssn_ein_masked_number ? (
                <Tooltip title={'Show full SSN'}>
                  <Button
                    onClick={() => {
                      getFullSSN(element);
                    }}
                    style={{ minWidth: '35px' }}
                    disabled={!isCompleted}
                  >
                    <Visibility fontSize='small' style={{ color: 'black' }} />
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
            </span>
          </div>
        ),
        source: element?.verified_by_user_id
          ? 'Manually Verified'
          : element?.is_verified_with_source
            ? element?.verifiedSource
            : '',
        note: <div dangerouslySetInnerHTML={{ __html: element?.note || element?.tx_error }}></div>,
        view: (
          <Tooltip title='View more information for the field'>
            {' '}
            <MoreVert
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCurData(element);
                setOpen(true);
              }}
              className='action-menu-item cursor-pointer'
            />
          </Tooltip>
        ),
        status: (
          <div>
            {element?.status === 'Verified' ? (
              <div
                style={{
                  color: 'green',
                }}
              >
                Verified
              </div>
            ) : element?.status === 'Pending' ? (
              <Tooltip
                title={
                  element?.tx_error ? (
                    <div dangerouslySetInnerHTML={{ __html: element?.tx_error }}></div>
                  ) : (
                    ''
                  )
                }
              >
                <ErrorOutline fontSize='small' style={{ color: 'orange' }} />
              </Tooltip>
            ) : (
              <>-</>
            )}
          </div>
        ),
      };
    });
    const groupedDataMap = tempData.reduce((acc, item) => {
      const { groupLabel, ...record } = item; // Extract groupLabel and the rest of the record
      if (!acc[groupLabel]) {
        acc[groupLabel] = [];
      }
      acc[groupLabel].push(record);
      return acc;
    }, {});

    const updatedData = Object.entries(groupedDataMap).map(([groupLabel, records]) => ({
      title: groupLabel,
      records,
    }));

    return updatedData;
  };

  return (
    <Box className='dashboard-main'>
      {(singleLoader ||
        sourceLoading ||
        docLoading ||
        loading ||
        dlLoading ||
        elementFetchLoading) && <Loader />}
      <SourceDetailsModal
        curData={curData}
        open={valueChangeModalOpen}
        setOpen={setValueChangeModalOpen}
        onVerify={onVerify}
      />
      <CommonModal
        open={dlConfirmationModalOpen}
        setOpen={setDlConfirmationModalOpen}
        title={'Confirmation'}
        description={
          statusData === 'verified'
            ? `Are you sure you want to verify government-issued ID? Please ensure that all details in the government-issued ID have been carefully reviewed.`
            : 'Are you sure you want to reject government-issued ID?'
        }
        handleSave={handleVerifyRejectDL}
      />
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                Participant Questionnaire Details (
                {details?.user_participant_type_name ? details?.user_participant_type_name : ''})
              </Typography>
              <Tooltip title={!isCompleted ? 'Participant has not completed form yet.' : ''}>
                {selectedFields?.length ? (
                  <Button
                    variant='outlined'
                    disabled={!isCompleted}
                    onClick={() => {
                      verifyMultiple(false);
                    }}
                    className='mr-8'
                  >
                    {`Verify ${selectedFields?.length + ' Fields'}`}
                  </Button>
                ) : (
                  ''
                )}
                {!orderDetails?.is_bank_account_client &&
                  details?.data_verify_status !== 'completed' ? (
                  <Button
                    variant='contained'
                    disabled={!isCompleted || !allIdVerified}
                    onClick={() => {
                      verifyMultiple(true);
                    }}
                  >
                    {`Complete Verification`}
                  </Button>
                ) : (
                  ''
                )}
              </Tooltip>
              {details?.data_verify_status == 'completed' ? (
                <Chip
                  label={`Verification Status : ${details?.data_verify_status}`}
                  style={{
                    backgroundColor: '#2e7d32',
                  }}
                  className='mui-tag p-8 f-16'
                />
              ) : (
                ''
              )}
            </Grid>
            <Box>
              <CardContent>
                {!orderDetails?.is_bank_account_client && !isCompleted ? (
                  <Alert severity='warning' className='f-13'>
                    You can not verify form fields as the participant has not completed the form
                    yet.
                  </Alert>
                ) : (
                  ''
                )}
                <Grid container spacing={2} className='client-user-form sub-header'>
                  {!orderDetails?.is_bank_account_client ? (
                    <Grid
                      xs={5}
                      className='question-section-container'
                      style={{ height: '300px', overflowY: 'scroll' }}
                    >
                      <div className='section-title-label f-15'>{'Documents'}</div>
                      <Grid container className='form-field-container mt-12'>
                        <DocumentLink
                          handleClick={() => statementPdf()}
                          disabled={!isCompleted}
                          label={`${details?.user_participant_type_name ?? ''
                            } Statement Of Information`}
                        />

                        {additionalDocs?.length ? (
                          additionalDocs.map((doc) => {
                            return (
                              <DocumentLink
                                disabled={!isCompleted}
                                handleClick={() =>
                                  downloadAdditionalDocs(doc?.name, doc?.participant_number)
                                }
                                label={doc?.label}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}

                        {docs?.length ? (
                          docs.map((doc) => {
                            return (
                              <DocumentLink
                                label={doc?.doc_name || 'Document'}
                                handleClick={() => getExtraDoc(doc?.url)}
                                disabled={!isCompleted}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item md={0.1}></Grid>
                  {!orderDetails?.is_bank_account_client ? (
                    <Grid
                      xs={6.8}
                      className='question-section-container'
                      style={{ height: '300px', overflowY: 'scroll' }}
                    >
                      <div className='section-title-label f-15'>{'Summary'}</div>
                      <Grid container className='form-field-container mt-12'>
                        {summary?.length && !(loading || dlLoading || elementFetchLoading) ? (
                          summary?.map((row) => {
                            return (
                              <Grid
                                style={{
                                  border: '1px solid rgb(234 230 230)',
                                  borderRadius: '4px',
                                }}
                                item
                                sm={12}
                                className='f-12 fw-500 p-6 mt-6'
                              >
                                <div className='d-flex align-items-center'>
                                  {row?.color === 'green' ? (
                                    <CheckCircleOutline
                                      style={{ color: 'green', fontSize: '14px' }}
                                    />
                                  ) : (
                                    <ErrorOutline style={{ color: row?.color, fontSize: '14px' }} />
                                  )}
                                  <span
                                    className='ml-8'
                                    dangerouslySetInnerHTML={{ __html: row?.message }}
                                  ></span>
                                </div>
                                {/* <hr
                                  className='mt-4 mb-8 w-100p'
                                  style={{ backgroundColor: '#d2cece' }}
                                /> */}
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid
                            item
                            sm={12}
                            className='d-flex justify-center align-items-center color-g f-12 fw-400'
                          >
                            No Summary Available
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {Object.keys(dlDetails?.transaction_response || {})?.length && !isEntity ? (
                    <Grid xs={12} className='question-section-container'>
                      <div className='section-title-label f-15'>{details?.user_participant_type_name}({details?.order_participant?.full_name ?? ""})'s{" "}
                        {dlDetails?.transaction_response?.IdentificationTypeId &&
                          ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          : 'Government Issued ID'}{' '}
                        <span
                          style={{
                            color:
                              dlDetails?.manual_verification_status === 'verified'
                                ? 'green'
                                : 'red',
                          }}
                        >
                          {dlDetails?.manual_verification_status?.length
                            ? `(Manually ${dlDetails?.manual_verification_status === 'verified'
                              ? 'Verified'
                              : 'Rejected'
                            })`
                            : ''}
                        </span>
                        {dlDetails?.transaction_response?.Approved ? (
                          <span className='color-s'>
                            (Verified
                            <VerifiedUser style={{ fontSize: '14px', marginBottom: '-2px' }} />)
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      {(dlDetails?.transaction_response?.NotApproved ||
                        !dlDetails?.transaction_response?.Approved) &&
                        !dlDetails?.manual_verification_status?.length ? (
                        <Alert
                          severity='error'
                          className='f-13 mt-8 fw-450 w-100p'
                          style={{ padding: '2px 10px' }}
                        >
                          {`Participant's government-issued ID has not been verified electronically. Please check and manually verify the government-issued ID. You can not complete the form without manually verifying or rejecting the government-issued ID.`}
                        </Alert>
                      ) : (
                        ''
                      )}
                      <ImageList cols={3} gap={20} variant='quilted'>
                        {dlDetails?.dl_image_1?.length ? (
                          <ImageListItem key={dlDetails?.dl_image_1}>
                            <img
                              src={`${dlDetails?.dl_image_1}`}
                              alt='gov-id-front'
                              className='driving-license'
                            />
                            <ImageListItemBar
                              title={`${dlDetails?.transaction_response?.IdentificationTypeId &&
                                ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                                ? ID_MAPPING?.[
                                dlDetails?.transaction_response?.IdentificationTypeId
                                ]
                                : 'Government Issued ID'
                                } ${dlDetails?.dl_image_2?.length ? '[Front]' : ''}`}
                            // subtitle={
                            //   `${
                            //     dlDetails?.transaction_response?.FirstName
                            //       ? dlDetails?.transaction_response?.FirstName
                            //       : ''
                            //   }` +
                            //   ' ' +
                            //   `${
                            //     dlDetails?.transaction_response?.FirstSurname
                            //       ? dlDetails?.transaction_response?.FirstSurname
                            //       : ''
                            //   }`
                            // }
                            />
                          </ImageListItem>
                        ) : (
                          ''
                        )}
                        {dlDetails?.dl_image_2?.length ? (
                          <ImageListItem key={dlDetails?.dl_image_2}>
                            <img
                              src={`${dlDetails?.dl_image_2}`}
                              alt='gov-id-back'
                              className='driving-license'
                            />
                            <ImageListItemBar
                              title={`${dlDetails?.transaction_response?.IdentificationTypeId &&
                                ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                                ? ID_MAPPING?.[
                                dlDetails?.transaction_response?.IdentificationTypeId
                                ]
                                : 'Government Issued ID'
                                } [Back]`}
                            // subtitle={
                            //   `${
                            //     dlDetails?.transaction_response?.FirstName
                            //       ? dlDetails?.transaction_response?.FirstName
                            //       : ''
                            //   }` +
                            //   ' ' +
                            //   `${
                            //     dlDetails?.transaction_response?.FirstSurname
                            //       ? dlDetails?.transaction_response?.FirstSurname
                            //       : ''
                            //   }`
                            // }
                            />
                          </ImageListItem>
                        ) : (
                          ''
                        )}
                      </ImageList>
                      {(dlDetails?.transaction_response?.NotApproved ||
                        !dlDetails?.transaction_response?.Approved) &&
                        !dlDetails?.manual_verification_status?.length ? (
                        <div className='d-flex justify-end'>
                          {' '}
                          <Button
                            autoFocus
                            color='primary'
                            variant='contained'
                            style={{ padding: '4px 8px' }}
                            onClick={() => {
                              setCurRecordId(details?.order_participant_id);
                              setDlConfirmationModalOpen(true);
                              setStatusData('verified');
                            }}
                            disabled={!isCompleted}
                          >
                            Verify
                          </Button>
                          <Button
                            autoFocus
                            color='error'
                            variant='contained'
                            className='ml-8'
                            style={{ padding: '4px 8px' }}
                            onClick={() => {
                              setCurRecordId(details?.order_participant_id);
                              setDlConfirmationModalOpen(true);
                              setStatusData('unverified');
                            }}
                            disabled={!isCompleted}
                          >
                            Reject
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ) : (
                    ''
                  )}
                  {additionalDlDetails?.map((addPartDetails) => {
                    return (
                      <Grid xs={12} className='question-section-container'>
                        <div className='section-title-label f-15'>
                          Additional {details?.user_participant_type_name}({addPartDetails?.participant_details?.full_name})'s{' '}
                          {addPartDetails?.transaction_response?.IdentificationTypeId &&
                            ID_MAPPING?.[addPartDetails?.transaction_response?.IdentificationTypeId]
                            ? ID_MAPPING?.[
                            addPartDetails?.transaction_response?.IdentificationTypeId
                            ]
                            : 'Government Issued ID'}{' '}
                          <span
                            style={{
                              color:
                                addPartDetails?.manual_verification_status === 'verified'
                                  ? 'green'
                                  : 'red',
                            }}
                          >
                            {addPartDetails?.manual_verification_status?.length
                              ? `(Manually ${addPartDetails?.manual_verification_status === 'verified'
                                ? 'Verified'
                                : 'Rejected'
                              })`
                              : ''}
                          </span>
                          {addPartDetails?.transaction_response?.Approved ? (
                            <span className='color-s'>
                              (Verified
                              <VerifiedUser style={{ fontSize: '14px', marginBottom: '-2px' }} />)
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        {(addPartDetails?.transaction_response?.NotApproved ||
                          !addPartDetails?.transaction_response?.Approved) &&
                          !addPartDetails?.manual_verification_status?.length ? (
                          <Alert
                            severity='error'
                            className='f-13 mt-8 fw-450 w-100p'
                            style={{ padding: '2px 10px' }}
                          >
                            {`Participant's government-issued ID has not been verified electronically. Please check and manually verify the government-issued ID. You can not complete the form without manually verifying or rejecting the government-issued ID.`}
                          </Alert>
                        ) : (
                          ''
                        )}
                        <ImageList cols={3} gap={20} variant='quilted'>
                          {addPartDetails?.dl_image_1?.length ? (
                            <ImageListItem key={addPartDetails?.dl_image_1}>
                              <img
                                src={`${addPartDetails?.dl_image_1}`}
                                alt='gov-id-front'
                                className='driving-license'
                              />
                              <ImageListItemBar
                                title={`${addPartDetails?.transaction_response?.IdentificationTypeId &&
                                  ID_MAPPING?.[
                                  addPartDetails?.transaction_response?.IdentificationTypeId
                                  ]
                                  ? ID_MAPPING?.[
                                  addPartDetails?.transaction_response?.IdentificationTypeId
                                  ]
                                  : 'Government Issued ID'
                                  } ${addPartDetails?.dl_image_2?.length ? '[Front]' : ''}`}
                              // subtitle={
                              //   `${
                              //     dlDetails?.transaction_response?.FirstName
                              //       ? dlDetails?.transaction_response?.FirstName
                              //       : ''
                              //   }` +
                              //   ' ' +
                              //   `${
                              //     dlDetails?.transaction_response?.FirstSurname
                              //       ? dlDetails?.transaction_response?.FirstSurname
                              //       : ''
                              //   }`
                              // }
                              />
                            </ImageListItem>
                          ) : (
                            ''
                          )}
                          {addPartDetails?.dl_image_2?.length ? (
                            <ImageListItem key={addPartDetails?.dl_image_2}>
                              <img
                                src={`${addPartDetails?.dl_image_2}`}
                                alt='gov-id-back'
                                className='driving-license'
                              />
                              <ImageListItemBar
                                title={`${addPartDetails?.transaction_response?.IdentificationTypeId &&
                                  ID_MAPPING?.[
                                  addPartDetails?.transaction_response?.IdentificationTypeId
                                  ]
                                  ? ID_MAPPING?.[
                                  addPartDetails?.transaction_response?.IdentificationTypeId
                                  ]
                                  : 'Government Issued ID'
                                  } [Back]`}
                              // subtitle={
                              //   `${
                              //     dlDetails?.transaction_response?.FirstName
                              //       ? dlDetails?.transaction_response?.FirstName
                              //       : ''
                              //   }` +
                              //   ' ' +
                              //   `${
                              //     dlDetails?.transaction_response?.FirstSurname
                              //       ? dlDetails?.transaction_response?.FirstSurname
                              //       : ''
                              //   }`
                              // }
                              />
                            </ImageListItem>
                          ) : (
                            ''
                          )}
                        </ImageList>
                        {(addPartDetails?.transaction_response?.NotApproved ||
                          !addPartDetails?.transaction_response?.Approved) &&
                          !addPartDetails?.manual_verification_status?.length ? (
                          <div className='d-flex justify-end'>
                            {' '}
                            <Button
                              autoFocus
                              color='primary'
                              variant='contained'
                              style={{ padding: '4px 8px' }}
                              onClick={() => {
                                setCurRecordId(addPartDetails?.participant_details?.id);
                                setDlConfirmationModalOpen(true);
                                setStatusData('verified');
                              }}
                              disabled={!isCompleted}
                            >
                              Verify
                            </Button>
                            <Button
                              autoFocus
                              color='error'
                              variant='contained'
                              className='ml-8'
                              style={{ padding: '4px 8px' }}
                              onClick={() => {
                                setCurRecordId(addPartDetails?.participant_details?.id);
                                setDlConfirmationModalOpen(true);
                                setStatusData('unverified');
                              }}
                              disabled={!isCompleted}
                            >
                              Reject
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </Grid>
                    );
                  })}
                  <Grid xs={12} className='question-section-container'>
                    <div className='section-title-label f-15'>{'Questionnaire Response'}</div>

                    {Object.keys(allElements)?.map((section, index) => {
                      return (
                        <Accordion defaultExpanded={index == 0}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            {section}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2} className='form-field-container' rowGap={1}>
                              <Grid item sm={12} md={12}>
                                <ViewFormDetailsTable
                                  // isClickable={true}
                                  // onRowClick={onRowClick}
                                  loader={loading}
                                  columns={columns}
                                  // data={allElements?.[section]?.map((element) => {
                                  //   return { label: element?.label, value: element?.user_value };
                                  // })}
                                  data={getTableData(allElements?.[section])}
                                  hidePagination={true}
                                />
                              </Grid>{' '}
                              {/* {allElements?.[section]?.map((element) => {
                                return (
                                  <Grid item xs={12} sm={6} md={4} className='verification-field'>
                                    <label style={{ color: 'grey' }}>{element?.label}</label>
                                    {element?.required && <label className='required'>{'*'}</label>}
                                    <div className='d-flex'>
                                      <ValidationTextField
                                        onClick={(e) => {
                                          onSelect(element);
                                        }}
                                        style={{
                                          caretColor: 'transparent',
                                          cursor: `${
                                            element?.is_verified_with_source ||
                                            element?.verified_by_user_id
                                              ? 'not-allowed'
                                              : 'pointer'
                                          }`,
                                          padding: `${
                                            !selectedFields
                                              .map((field) => field.element_id)
                                              .includes(element.element_id)
                                              ? 'unset'
                                              : '4px'
                                          }`,
                                          border: `${
                                            !selectedFields
                                              .map((field) => field.element_id)
                                              .includes(element.element_id)
                                              ? 'none'
                                              : '2px solid black'
                                          }`,
                                        }}
                                        // value={
                                        //   fullSSNs?.[element?.element_name]
                                        //     ? fullSSNs?.[element?.element_name]
                                        //     : element?.user_value
                                        // }
                                        value={
                                          SSN_FIELDS?.includes(element?.element_name)
                                            ? fullSSNs?.[element?.element_name]
                                              ? fullSSNs?.[element?.element_name]
                                              : element?.ssn_ein_masked_number
                                            : element?.user_value
                                        }
                                        name={element?.label}
                                        variant='outlined'
                                        fullWidth
                                        readonly
                                        focused={false}
                                        disabled={
                                          element?.is_verified_with_source ||
                                          element?.verified_by_user_id ||
                                          !isCompleted ||
                                          details?.data_verify_status == 'completed'
                                        }
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <Tooltip
                                                title={
                                                  element?.is_verified_with_source ||
                                                  element?.verified_by_user_id
                                                    ? 'View verification details.'
                                                    : 'Verify field.'
                                                }
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setCurData(element);
                                                    setOpen(true);
                                                  }}
                                                >
                                                  {element?.is_verified_with_source ||
                                                  element?.verified_by_user_id ? (
                                                    <CheckCircleOutline
                                                      fontSize='small'
                                                      style={{ color: 'green', cursor: 'pointer' }}
                                                    />
                                                  ) : (
                                                    <ErrorOutline
                                                      fontSize='small'
                                                      style={{ color: 'orange', cursor: 'pointer' }}
                                                    />
                                                  )}
                                                </IconButton>
                                              </Tooltip>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      {SSN_FIELDS?.includes(element?.element_name) &&
                                      !fullSSNs?.[element?.element_name] ? (
                                        <Tooltip title={'Show full SSN'}>
                                          <Button
                                            onClick={() => {
                                              getFullSSN(element);
                                            }}
                                            style={{ minWidth: '35px' }}
                                            disabled={!isCompleted}
                                          >
                                            <Visibility
                                              fontSize='small'
                                              style={{ color: 'black' }}
                                            />
                                          </Button>
                                        </Tooltip>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    {element?.tx_error ? (
                                      <div
                                        className='color-w f-12'
                                        dangerouslySetInnerHTML={{ __html: element?.tx_error }}
                                      ></div>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                );
                              })} */}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        // <Grid xs={12} className='question-section-container'>
                        //   <div className='section-title-label'>{section}</div>
                        //   <Grid container spacing={2} className='form-field-container' rowGap={1}>
                        //     {allElements?.[section]?.map((element, index) => {
                        //       return (
                        //         <Grid item xs={12} sm={6} md={4} className='verification-field'>
                        //           <label style={{ color: 'grey' }}>{element?.label}</label>
                        //           {element?.required && <label className='required'>{'*'}</label>}
                        //           <div className='d-flex'>
                        //             <ValidationTextField
                        //               onClick={(e) => {
                        //                 onSelect(element);
                        //               }}
                        //               style={{
                        //                 caretColor: 'transparent',
                        //                 cursor: `${
                        //                   element?.is_verified_with_source ||
                        //                   element?.verified_by_user_id
                        //                     ? 'not-allowed'
                        //                     : 'pointer'
                        //                 }`,
                        //                 padding: `${
                        //                   !selectedFields
                        //                     .map((field) => field.element_id)
                        //                     .includes(element.element_id)
                        //                     ? 'unset'
                        //                     : '4px'
                        //                 }`,
                        //                 border: `${
                        //                   !selectedFields
                        //                     .map((field) => field.element_id)
                        //                     .includes(element.element_id)
                        //                     ? 'none'
                        //                     : '2px solid black'
                        //                 }`,
                        //               }}
                        //               // value={
                        //               //   fullSSNs?.[element?.element_name]
                        //               //     ? fullSSNs?.[element?.element_name]
                        //               //     : element?.user_value
                        //               // }
                        //               value={
                        //                 SSN_FIELDS?.includes(element?.element_name)
                        //                   ? fullSSNs?.[element?.element_name]
                        //                     ? fullSSNs?.[element?.element_name]
                        //                     : element?.ssn_ein_masked_number
                        //                   : element?.user_value
                        //               }
                        //               name={element?.label}
                        //               variant='outlined'
                        //               fullWidth
                        //               readonly
                        //               focused={false}
                        //               disabled={
                        //                 element?.is_verified_with_source ||
                        //                 element?.verified_by_user_id ||
                        //                 !isCompleted ||
                        //                 details?.data_verify_status == 'completed'
                        //               }
                        //               InputProps={{
                        //                 endAdornment: (
                        //                   <InputAdornment position='end'>
                        //                     <Tooltip
                        //                       title={
                        //                         element?.is_verified_with_source ||
                        //                         element?.verified_by_user_id
                        //                           ? 'View verification details.'
                        //                           : 'Verify field.'
                        //                       }
                        //                     >
                        //                       <IconButton
                        //                         onClick={(e) => {
                        //                           e.preventDefault();
                        //                           e.stopPropagation();
                        //                           setCurData(element);
                        //                           setOpen(true);
                        //                         }}
                        //                       >
                        //                         {element?.is_verified_with_source ||
                        //                         element?.verified_by_user_id ? (
                        //                           <CheckCircleOutline
                        //                             fontSize='small'
                        //                             style={{ color: 'green', cursor: 'pointer' }}
                        //                           />
                        //                         ) : (
                        //                           <ErrorOutline
                        //                             fontSize='small'
                        //                             style={{ color: 'orange', cursor: 'pointer' }}
                        //                           />
                        //                         )}
                        //                       </IconButton>
                        //                     </Tooltip>
                        //                   </InputAdornment>
                        //                 ),
                        //               }}
                        //             />
                        //             {SSN_FIELDS?.includes(element?.element_name) &&
                        //             !fullSSNs?.[element?.element_name] ? (
                        //               <Tooltip title={'Show full SSN'}>
                        //                 <Button
                        //                   onClick={() => {
                        //                     getFullSSN(element);
                        //                   }}
                        //                   style={{ minWidth: '35px' }}
                        //                   disabled={!isCompleted}
                        //                 >
                        //                   <Visibility fontSize='small' style={{ color: 'black' }} />
                        //                 </Button>
                        //               </Tooltip>
                        //             ) : (
                        //               ''
                        //             )}
                        //           </div>
                        //           {element?.tx_error ? (
                        //             <div
                        //               className='color-w f-12'
                        //               dangerouslySetInnerHTML={{ __html: element?.tx_error }}
                        //             ></div>
                        //           ) : (
                        //             ''
                        //           )}
                        //         </Grid>
                        //       );
                        //     })}
                        //   </Grid>
                        // </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      <SourceDetailsDrawerUpdated
        disabled={!isCompleted || details?.data_verify_status === 'completed'}
        open={open}
        setOpen={setOpen}
        curData={curData}
        sourceList={sourceList}
        formValues={formValues}
        setFormValues={setFormValues}
        handleBlur={handleBlur}
      />
      <VerificationConfirmationModal
        open={verificationModalOpen}
        setOpen={setVerificationModalOpen}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        onVerify={onVerify}
        isAll={isAllSelected}
      />
      <DocumentViewer
        open={docModalOpen}
        setOpen={setDocModalOpen}
        fileUrl={url}
        title={'Document'}
        originalUrl={originalUrl}
      />
      <AutoCompleteModal
        open={autoCompleteModalOpen}
        setOpen={setAutoCompleteModalOpen}
        onVerify={onAutoCompleteVerification}
      />
    </Box>
  );
};
export default ViewFormDetails;
