import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Box, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import chroma from 'chroma-js';
import StepsHeader from '../../../components/questions/home/StepsHeader';
import StepDetails from '../../../components/questions/home/StepDetails';
import SideBar from '../../../components/questions/home/SideBar';
import {
  getSignature,
  getQuestionDetails,
  saveAnswer,
  getDocumentDetails,
  getCard,
  translateContent,
  verifyBankDetails,
} from '../../../services/questions/home';
import {
  ADDITIONAL_SELLER_TYPE,
  BANK_ACCOUNT_NAME,
  BANK_ACCOUNT_NUMBER,
  BANK_ACCOUNT_SECTION,
  BANK_CITY,
  BANK_NAME,
  BANK_STATE,
  BORROWER_LENDER_SECTION,
  dlSectionIds,
  DocElementType,
  FIELDS_CHECK_VALIDATIONS,
  IS_INDIVIDUAL_ENTITY,
  LENDER_INFO_ID,
  LOAN_UPLOAD_NAME,
  MAIN_BANK_QUE,
  ROUTING_NUMBER_FIELD,
  SSN_FIELDS,
  STATE_FIELDS,
} from '../../../utils/constants';
import {
  addErrorDetails,
  addQuestionDetails,
  fetchFormDetails,
  clearFormValue,
  setDocuments,
  addCurrentStep,
  clearTouchedField,
  addCreditCardInfo,
  addConvertedTexts,
  addLanguage,
  addQRDetails,
  addIncompleteSectionDetails,
} from '../../../store/slices/questionSlice';
import {
  errorChecker,
  findElement,
  findElementByName,
  splitTexts,
  startsWithAny,
  updateSourceData,
} from '../../../utils/helpers';
import Loader from '../../../components/common/Loader';
import LastStep from '../../../components/questions/home/LastStep';
import useRedux from '../../../utils/hooks/useRedux';
import { resources } from '../../../i18n';
import StepValidationModal from '../../../components/questions/home/StepValidationModal';
import { useTranslation } from 'react-i18next';
import BankAccountVerificationModal from '../../../components/questions/home/BankAccountVerificationModal';
import { verifyElement } from '../../../services/admin/participantForms';

const Home = () => {
  const { code, participantNo } = useParams();
  const { t } = useTranslation();
  const { getFormValue, getDocument } = useRedux();
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector((state) => state.questionSlice.stepData)?.[code];
  const [activeSection, setActiveSection] = useState(currentStep || 0);
  const [signature, setSignature] = useState({});
  const [spouseSignature, setSpouseSignature] = useState({});
  const [sigDataURL, setSigDataURL] = useState(null);
  const [isShowAlertMessage, setShowAlertMessage] = useState(false);
  const [isShowSubmissionPage, setShowSubmissionPage] = useState(false);
  const [stepValidationModalOpen, setStepValidationModalOpen] = useState(false);
  const [bankAccVerificationModal, setBankAccountVerificationModal] = useState(false);
  const [bankError, setBankError] = useState(false);
  const [isBankStep, setIsBankStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [currentJumpIndex, setCurrentJumpIndex] = useState(null);
  const authData = useSelector((state) => state?.authSlice);
  const orderInfo = useSelector((state) => state.questionSlice.orderDetails)?.find(
    (order) => order.tx_id === code
  )?.order;
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const documents = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.documents;
  const isDisableForm = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.is_pdf_generated;
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const sections = question?.sections
    ?.filter((section) => !section?.is_hidden && (!(
      isAdditionalParticipant &&
      authData?.[code]?.participant_type_name === "Buyer" &&
      section.section_label === "Disbursement of Funds"
    )))

    .map((section) => section.section_label);
  const qrDetails = useSelector((state) => state.questionSlice.qrDetails)?.[code];

  const translatedSections = question?.sections
    ?.filter((section) => !section?.is_hidden && (!(
      isAdditionalParticipant &&
      authData?.[code]?.participant_type_name === "Buyer" &&
      section.section_label === "Disbursement of Funds"
    )))
    .map((section) => {
      return languageForLabel
        ? translations?.[languageForLabel]?.sections?.[section?.section_id]
          ? translations?.[languageForLabel]?.sections?.[section?.section_id]
          : section?.section_label
        : section?.section_label;
    });
  const companyDetails = useSelector((state) => state.questionSlice.companyDetails)?.find(
    (company) => company.tx_id === code
  )?.company;
  const creditCardInformation = useSelector((state) => state.questionSlice.creditCardInfo)?.find(
    (que) => que.tx_id === code
  );
  const paddingDetails = useSelector((state) => state.questionSlice.paddingDetails)?.find(
    (que) => que.tx_id === code
  );
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];
  const incompleteSections = useSelector((state) => state.questionSlice.incompleteSections)?.[code];

  useEffect(() => {
    if (Object.keys(companyDetails?.branding_json || {})?.length) {
      document.documentElement.style.setProperty(
        '--primary-color',
        companyDetails?.branding_json?.primary_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-color',
        companyDetails?.branding_json?.sidebar_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-font-color',
        companyDetails?.branding_json?.sidebar_font_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-font-darker-color',
        companyDetails?.branding_json?.sidebar_font_color
          ? chroma(companyDetails?.branding_json?.sidebar_font_color).darken(0.5).hex()
          : ''
      );
      document.documentElement.style.setProperty(
        '--sidebar-lighten-color',
        companyDetails?.branding_json?.sidebar_color
          ? chroma(companyDetails?.branding_json?.sidebar_color).brighten(0.5).hex()
          : ''
      );
      document.documentElement.style.setProperty(
        '--header-color',
        companyDetails?.branding_json?.header_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--participant-header-bg-color',
        companyDetails?.branding_json?.header_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--header-font-color',
        companyDetails?.branding_json?.header_font_color ?? ''
      );
      document.documentElement.style.setProperty(
        '--bg-color',
        companyDetails?.branding_json?.background_color ?? ''
      );
    }
  }, [companyDetails]);

  useLayoutEffect(() => {
    if (orderInfo?.order_id && !authData?.[code]?.is_bank_account_client) {
      getDocuments(orderInfo?.order_id);
    }
  }, [orderInfo?.order_id]);

  useLayoutEffect(() => {
    if (
      orderInfo?.order_id &&
      order_part_participant_id &&
      !authData?.[code]?.is_bank_account_client
    ) {
      getSignatureData();
      // getCreditCardInfo();
    }
  }, [orderInfo?.order_id, order_part_participant_id]);

  useLayoutEffect(() => {
    if (code) {
      getQuestionData(code);
    }
  }, [code]);
  useEffect(() => {
    if (!authData?.[code]?.token) {
      navigate(
        participantNo ? `/questions/invite/${code}/${participantNo}` : `/questions/invite/${code}`
      );
    }
  }, [authData?.[code]?.token]);

  const getDocuments = async (orderId) => {
    setLoading(true);
    const documentData = await getDocumentDetails(
      {
        order_id: orderId,
      },
      authData?.[code]?.token
    );
    if (documentData?.success) {
      dispatch(setDocuments({ documents: documentData?.response?.files, tx_id: code }));
    }
    setLoading(false);
  };

  const getCreditCardInfo = async () => {
    setLoading(true);
    const cardData = await getCard(order_part_participant_id, authData?.[code]?.token);
    if (cardData?.success && cardData?.response?.id) {
      dispatch(addCreditCardInfo({ show: true, info: cardData?.response || {}, tx_id: code }));
    } else {
      dispatch(addCreditCardInfo({ show: false, info: {}, tx_id: code }));
    }
    setLoading(false);
  };

  const getQuestionData = async (id) => {
    setLoading(true);
    const queData = await getQuestionDetails(id, authData?.[code]?.token);
    if (queData?.success) {
      dispatch(
        addQuestionDetails({
          tx_id: code,
          is_pdf_generated: queData?.response?.is_pdf_generated || false,
          questions: queData?.response?.question?.cached_questions_json?.question,
          template_id: queData?.response?.template_id,
          sources: queData?.response?.source || [],
          order_part_participant_id: queData?.response?.question?.id,
          documents: queData?.response?.additional_docs || {},
        })
      );
    } else {
      toast.error(queData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  const getSignatureData = async () => {
    setLoading(true);
    const signatureData = await getSignature(
      authData?.[code]?.token,
      'participant',
      orderInfo?.order_id,
      1,
      order_part_participant_id
    );
    if (signatureData?.success) {
      setSignature(signatureData?.response);
    }
    const spouseSignatureData = await getSignature(
      authData?.[code]?.token,
      authData?.[code]?.is_spouse ? 'spouse' : 'participant',
      orderInfo?.order_id,
      participantNo,
      order_part_participant_id
    );
    if (spouseSignatureData?.success) {
      setSpouseSignature(spouseSignatureData?.response);
    }
    setLoading(false);
  };

  const saveAnswerData = async (values) => {
    const currentActiveSection = question?.sections?.findIndex(
      (section) => section?.section_label === sections?.[activeSection]
    );
    const grouped = _.groupBy(values, 'groupIndex');
    const data = _.mapValues(grouped, (items) => _.map(items, 'id'));
    const formValues = _.reduce(
      values,
      (acc, item) => {
        acc[item.id] = item.value;
        return acc;
      },
      {}
    );
    let payload = {
      section_index: currentActiveSection,
      data,
      values: formValues,
      participant_uuid: code,
      order_id: orderInfo?.order_id,
      user_data_participant_id: authData?.[code]?.id,
      order_part_id: '',
      participant_no: participantNo || 1,
      section_id: question?.sections?.[currentActiveSection]?.section_id,
      is_lender_info_section:
        question?.sections?.[currentActiveSection]?.section_id &&
          (question?.sections?.[currentActiveSection]?.section_id === LENDER_INFO_ID ||
            question?.sections?.[currentActiveSection]?.section_id === BORROWER_LENDER_SECTION) &&
          !isAdditionalParticipant
          ? true
          : false,
    };
    const response = await saveAnswer(payload, authData?.[code]?.token);
    if (response.success) {
      let errors = Object.keys(response?.response?.errors || {});
      if (errors?.length) {
        errors?.forEach((error) => {
          dispatch(
            addErrorDetails({
              id: error,
              error: true,
              error_message: response?.response?.errors[error][0] || '',
              tx_id: code,
            })
          );
        });

        toast.error('Please validate your fields.');
        return false;
      } else {
        if (Object.keys(response?.response?.already_added_participant || {})?.length) {
          toast.success(
            `The additional seller(${Object.keys(
              response?.response?.already_added_participant || {}
            )
              ?.map((key) => response?.response?.already_added_participant?.[key])
              ?.join(
                ', '
              )})  you added have already been added by the escrow officer, and an invite link has already been sent.`
          );
        }
        dispatch(clearFormValue({ ids: Object.keys(formValues), tx_id: code }));
        dispatch(clearTouchedField({ ids: Object.keys(formValues), tx_id: code }));
        dispatch(
          addQuestionDetails({
            tx_id: code,
            questions: response?.response?.question?.cached_questions_json?.question,
            sources: response?.response?.question?.cached_questions_json?.sources || {},
            is_pdf_generated: response?.response?.is_pdf_generated,
            order_part_participant_id: response?.response?.question?.id,
            documents: response?.response?.additional_docs || {},
          })
        );
        return true;
      }
    } else {
      return false;
    }
  };

  let isNextStep = true,
    formValues = [];

  const UpdateOrderParticipantQuestion = async (element, groupIndex, skipValidation = false) => {
    const formData = await dispatch(fetchFormDetails({ code, id: element.element_id })).unwrap();
    let value =
      formData?.isTouched || SSN_FIELDS.find((field) => element?.element_name?.startsWith(field))
        ? formData.value
        : element.user_value;
    if (
      element.element_type === DocElementType.File ||
      element.element_type === DocElementType.Upload
    ) {
      value = formData.value;
    }
    const errorData = errorChecker(element, value);
    let ignoreRequireValidation = false;

    if (startsWithAny(element.element_name, FIELDS_CHECK_VALIDATIONS)) {
      const splits = element.element_name.split('_');
      const loanNum = splits[splits?.length - 1];
      const docData = getDocument(LOAN_UPLOAD_NAME + `_${loanNum}`);
      if (docData?.[0]?.file_name?.length) {
        ignoreRequireValidation = true;
      }
    }

    if (
      !element?.disabled &&
      !ignoreRequireValidation &&
      errorData.isError &&
      element?.element_type !== 'L' &&
      element?.element_type !== 'M' &&
      element?.element_type !== 'Z' &&
      element?.element_type !== 'LK'
    ) {
      dispatch(
        addErrorDetails({
          id: element.element_id,
          error: true,
          error_message: errorData?.message || '',
          tx_id: code,
        })
      );
      if (!skipValidation) isNextStep = false;
    }
    if (
      element.element_type !== DocElementType.File &&
      element.element_type !== DocElementType.Upload &&
      (skipValidation || ignoreRequireValidation ? true : !errorData?.isError) &&
      element.user_value != formData?.value
    ) {
      formValues.push({ id: element.element_id, value: value ?? '', groupIndex });
    }

    if (element.options.length && value) {
      const option = element.options.find((opt) => opt.option_name === value);
      if (option?.elements) {
        for (let sub_element of option?.elements) {
          if (!sub_element.display) continue;
          if (
            !isAdditionalParticipant &&
            sub_element?.participant_no &&
            sub_element?.participant_no > 1
          )
            continue;
          if (sub_element?.participant_no && sub_element?.participant_no != participantNo) continue;
          await UpdateOrderParticipantQuestion(sub_element, groupIndex, skipValidation);
        }
      }
    }
  };

  const checkNextStep = async (skipValidation, isDlErr = false) => {
    setLoading(true);
    const currentActiveSection = question?.sections?.findIndex(
      (section) => section?.section_label === sections?.[activeSection]
    );
    if (question?.sections?.[currentActiveSection]) {
      // if (
      //   question?.sections?.[currentActiveSection]?.section_id == HOA_SECTION_ID &&
      //   !isAdditionalParticipant
      // ) {
      //   const creditCardParentValue =
      //     getFormValue(HOA_MAIN_PARENT_IDX) ||
      //     findElement(question?.sections?.[currentActiveSection], HOA_MAIN_PARENT_IDX)
      //       ?.user_value ||
      //     '';
      //   if (
      //     creditCardParentValue &&
      //     creditCardParentValue === 'Yes' &&
      //     creditCardInformation?.show &&
      //     !creditCardInformation?.info?.id
      //   ) {
      //     setLoading(false);
      //     toast.error('Please add credit card information to proceed further.');
      //     return false;
      //   }
      // }
      formValues = [];
      isNextStep = true;
      for (let [index, group] of (question?.sections?.[currentActiveSection]?.groups).entries()) {
        if (group?.is_hidden) continue;
        for (let element of group.elements) {
          if (
            element.element_type !== DocElementType.Media &&
            element.element_type !== DocElementType.MediaLink &&
            element.display
          ) {
            if (
              !isAdditionalParticipant &&
              element?.participant_no &&
              element?.participant_no > 1
            ) {
              continue;
            }
            if (element?.participant_no && element?.participant_no != participantNo) {
              continue;
            }
            await UpdateOrderParticipantQuestion(element, index, skipValidation);
          }
        }
      }
      if ((isDlErr ? false : true) && isNextStep) {
        const updated = await saveAnswerData(formValues);
        setStepValidationModalOpen(false);
        if (!updated) isNextStep = false;
      }
      setShowAlertMessage(!isNextStep ? true : false);
      setLoading(false);
      return isNextStep;
    }
    setLoading(false);
    return false;
  };

  const setStepperDetails = async (forward = true, skipValidation = false, skipIndex = null) => {
    let isDlErr = false;
    setCurrentJumpIndex(skipIndex);
    if (forward) {
      const currentActiveSection = question?.sections?.findIndex(
        (section) => section?.section_label === sections?.[activeSection]
      );

      // for buyer DL Upload need to add Id for buyer dl section in constant

      if (
        dlSectionIds.includes(question?.sections?.[currentActiveSection]?.section_id) &&
        !skipValidation
      ) {
        if (isAdditionalParticipant) {
          const entityElem = findElementByName(
            question?.sections?.[currentActiveSection],
            authData?.[code]?.participant_type_name?.toLowerCase() +
            ADDITIONAL_SELLER_TYPE +
            `_${participantNo}`
          );
          if (entityElem) {
            const entityValue =
              getFormValue(entityElem?.element_id) ||
              findElement(question?.sections?.[currentActiveSection], entityElem?.element_id)
                ?.user_value ||
              '';
            if (entityValue && entityValue === 'Individual') {
              if (!Object.keys(dlDetails?.transaction_response || {})?.length) {
                isDlErr = true;
                dispatch(
                  addQRDetails({
                    tx_id: code,
                    data: {
                      ...qrDetails,
                      isError: true,
                    },
                  })
                );
              }
            }
          }
        } else {
          const entityValue =
            getFormValue(IS_INDIVIDUAL_ENTITY) ||
            findElement(question?.sections?.[currentActiveSection], IS_INDIVIDUAL_ENTITY)
              ?.user_value ||
            '';
          if (entityValue && entityValue === 'Individual') {
            if (!Object.keys(dlDetails?.transaction_response || {})?.length) {
              isDlErr = true;
              dispatch(
                addQRDetails({
                  tx_id: code,
                  data: {
                    ...qrDetails,
                    isError: true,
                  },
                })
              );
            }
          }
        }
      }

      const available =
        isDisableForm && !isAdditionalParticipant
          ? true
          : await checkNextStep(skipValidation, isDlErr);

      // added temporary conditions to check bank account fields
      const bankAccMain =
        getFormValue(MAIN_BANK_QUE) ||
        findElement(question?.sections?.[currentActiveSection], MAIN_BANK_QUE)?.user_value ||
        '';
      setIsBankStep(
        bankAccMain &&
        bankAccMain === 'Wire' &&
        question?.sections?.[currentActiveSection]?.section_id === BANK_ACCOUNT_SECTION
      );

      if (isDlErr || !available) {
        const validationMessage =
          isDlErr && !available
            ? t('bothValidationFailedMessage')
            : isDlErr
              ? t('stepDlValidationMessage')
              : t('stepValidationMessage');

        setValidationMessage(validationMessage);
        setStepValidationModalOpen(true);
      } else {
        // below logic is to verify bank account details
        if (
          question?.sections?.[currentActiveSection]?.section_id === BANK_ACCOUNT_SECTION &&
          bankAccMain &&
          bankAccMain === 'Wire' &&
          false
        ) {
          const bankAcc =
            getFormValue(BANK_ACCOUNT_NUMBER) ||
            findElement(question?.sections?.[currentActiveSection], BANK_ACCOUNT_NUMBER)
              ?.user_value ||
            '';
          const routingNo =
            getFormValue(ROUTING_NUMBER_FIELD) ||
            findElement(question?.sections?.[currentActiveSection], ROUTING_NUMBER_FIELD)
              ?.user_value ||
            '';
          const bankCity =
            getFormValue(BANK_CITY) ||
            findElement(question?.sections?.[currentActiveSection], BANK_CITY)?.user_value ||
            '';
          const bankState =
            getFormValue(BANK_STATE) ||
            findElement(question?.sections?.[currentActiveSection], BANK_STATE)?.user_value ||
            '';
          const bankName =
            getFormValue(BANK_NAME) ||
            findElement(question?.sections?.[currentActiveSection], BANK_NAME)?.user_value ||
            '';
          const nameOnAccount =
            getFormValue(BANK_ACCOUNT_NAME) ||
            findElement(question?.sections?.[currentActiveSection], BANK_ACCOUNT_NAME)
              ?.user_value ||
            '';
          if (bankAcc && routingNo) {
            setLoading(true);
            const res = await verifyBankDetails(
              {
                order_participant_id: authData?.[code]?.id,
                account_number: bankAcc,
                routing_number: routingNo,
                name_on_account: nameOnAccount ? nameOnAccount : '',
                bank_name: bankName ? bankName : '',
                bank_city: bankCity ? bankCity : '',
                bank_state: bankState ? bankState : '',
              },
              authData?.[code]?.token
            );
            setBankAccountVerificationModal(true);
            if (res?.success) {
              let queJson = _.cloneDeep(question);
              const newJson = updateSourceData(
                queJson,
                currentActiveSection,
                'Lyon Bank Account Verification',
                {
                  [BANK_ACCOUNT_NAME]: nameOnAccount,
                  [ROUTING_NUMBER_FIELD]: routingNo,
                  [BANK_ACCOUNT_NUMBER]: bankAcc,
                  [BANK_CITY]: res?.response?.primaryInstitution?.City,
                  [BANK_STATE]: res?.response?.primaryInstitution?.State,
                  [BANK_NAME]: bankName,
                }
              );
              if (newJson) {
                const res = await verifyElement(
                  code,
                  {
                    cached_questions_json: {
                      question: _.cloneDeep(newJson),
                    },
                    is_completed: false,
                  },
                  authData?.[code]?.token
                );
                if (!res?.success) {
                  return toast.error('Something went wrong!');
                }
              }
              setBankError('');
            } else {
              setBankError(res?.message);
            }
            setLoading(false);
            return;
          }
        }

        // remove current section from incomplete section list
        if (incompleteSections?.length && !skipValidation) {
          const curSection = sections?.[activeSection];
          const tempIncompleteSections = incompleteSections?.filter(
            (sec) => sec?.section_label?.replace('(Upload Government Issued ID)', '') !== curSection
          );
          dispatch(addIncompleteSectionDetails({ tx_id: code, data: tempIncompleteSections }));
        }
        dispatch(addCurrentStep({ tx_id: code, step: skipIndex ? skipIndex : activeSection + 1 }));
        setActiveSection((prev) => (skipIndex ? skipIndex : prev + 1));
        window.scrollTo(0, 0);
      }
    } else {
      dispatch(addCurrentStep({ tx_id: code, step: skipIndex ? skipIndex : activeSection - 1 }));
      setActiveSection((prev) => (skipIndex ? skipIndex : prev - 1));
      window.scrollTo(0, 0);
    }
  };

  // below function is to save PHH data
  const saveFormDataPHH = async (showMessage = true) => {
    const available = await checkNextStep(false, false);
    if (available && showMessage) {
      toast.success('Form information has been updated successfully.');
    }
  };

  const stepChange = (idx) => {
    if (idx < activeSection) {
      setActiveSection(idx);
      dispatch(addCurrentStep({ tx_id: code, step: idx }));
    }
  };

  const fetchAllElements = async () => {
    let elementsData = _.cloneDeep(question);
    let tempElements = [];
    let tempSections = [];
    let tempGroups = [];
    let tempOptions = [];
    const answer = async (element) => {
      if (element?.display) {
        if (element?.label && element?.label?.length) {
          tempElements.push({
            label: element.label,
            id: element.element_id,
            type: 'element',
          });
        }
      }

      if (element.options.length) {
        for (const option of element.options) {
          if (!STATE_FIELDS?.includes(element.element_id)) {
            tempOptions.push({ label: option.option_name, id: option.option_id, type: 'option' });
          }
          for (const subElement of option.elements) {
            await answer(subElement);
          }
        }
      }
    };
    for (const section of elementsData?.sections) {
      if (section?.is_hidden) continue;
      tempSections.push({
        label: section.section_label,
        id: section.section_id,
        type: 'section',
      });
      for (const group of section?.groups) {
        if (group.is_hidden) continue;
        tempGroups.push({
          label: group.group_label,
          id: group.group_id,
          type: 'group',
        });
        for (const element of group.elements) {
          await answer(element);
        }
      }
    }
    return {
      sections: tempSections,
      groups: tempGroups,
      elements: tempElements,
      options: tempOptions,
    };
  };

  const translate = async (target) => {
    setLoading(true);
    try {
      // const API_URL =
      //   'https://translation.googleapis.com/language/translate/v2?key=';
      if (translations?.[target]) {
        dispatch(addLanguage({ tx_id: code, language: target }));
        return setLoading(false);
      }
      let { sections, groups, elements, options } = await fetchAllElements();
      const combinedArr = [...sections, ...groups, ...elements, ...options];
      const texts = combinedArr.map((labelObj) => (labelObj.label ? labelObj.label : ''));

      let translatedTexts = [];
      let splitArray = splitTexts(texts);
      for (const arr of splitArray) {
        // const body = {
        //   q: arr,
        //   target: target,
        // };
        // const response = await axios.post(API_URL, body);
        // const translations = response.data.data.translations.map(
        //   (translation) => translation.translatedText
        // );
        // translatedTexts = [...translatedTexts, ...translations];

        const body = {
          text: arr,
          target: target,
        };
        const response = await translateContent(body, authData?.[code]?.token);
        if (response.success) {
          const translations = response?.response?.data?.translations?.map(
            (translation) => translation.translatedText
          );
          translatedTexts = [...translatedTexts, ...translations];
        } else {
          throw new Error('Something went wrong! Please try again.');
        }
      }
      let translatedSections = {};
      let translatedGroups = {};
      let translatedElements = {};
      let translatedOptions = {};
      translatedTexts.forEach((translatedLabel, index) => {
        if (combinedArr?.[index].type === 'section') {
          translatedSections[combinedArr?.[index]?.id] = translatedLabel;
        } else if (combinedArr?.[index].type === 'group') {
          translatedGroups[combinedArr?.[index]?.id] = translatedLabel;
        } else if (combinedArr?.[index].type === 'element') {
          translatedElements[combinedArr?.[index]?.id] = translatedLabel;
        } else if (combinedArr?.[index].type === 'option') {
          translatedOptions[combinedArr?.[index]?.id] = translatedLabel;
        }
      });

      // converting static content on form
      const staticObj = resources?.en?.translation;
      const staticArr = Object.keys(staticObj).map((key) => {
        return { key, value: staticObj[key] };
      });
      const staticArrToTranslate = staticArr.map((elem) => elem.value);
      let staticTranslatedObj = {};
      // const staticBody = {
      //   q: staticArrToTranslate,
      //   target: target,
      // };
      // const staticResponse = await axios.post(API_URL, staticBody);
      // const staticTranslations = staticResponse.data.data.translations.map(
      //   (translation) => translation.translatedText
      // );
      // staticTranslations.forEach((translatedLabel, index) => {
      //   staticTranslatedObj[staticArr[index]['key']] = translatedLabel;
      // });

      const staticBody = {
        text: staticArrToTranslate,
        target: target,
      };
      const staticResponse = await translateContent(staticBody, authData?.[code]?.token);
      if (staticResponse.success) {
        const staticTranslations = staticResponse?.response?.data?.translations?.map(
          (translation) => translation.translatedText
        );
        staticTranslations.forEach((translatedLabel, index) => {
          staticTranslatedObj[staticArr[index]['key']] = translatedLabel;
        });
      } else {
        throw new Error('Something went wrong! Please try again.');
      }

      dispatch(
        addConvertedTexts({
          tx_id: code,
          language: target,
          translations: {
            sections: translatedSections,
            groups: translatedGroups,
            elements: translatedElements,
            options: translatedOptions,
            static: staticTranslatedObj,
          },
        })
      );

      dispatch(addLanguage({ tx_id: code, language: target }));
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      console.error('Error translating labels:', error);
    }
  };

  // const saveNewLanguageLabels = async (target, sections, groups, elements) => {
  //   let convertedSectionObj = {};
  //   let convertedGroupObj = {};
  //   let convertedElementObj = {};

  //   sections.forEach((section) => (convertedSectionObj[section?.id] = section.label));
  //   groups.forEach((group) => (convertedGroupObj[group?.id] = group.label));
  //   elements.forEach((element) => (convertedElementObj[element?.id] = element.label));

  //   let elementsData = _.cloneDeep(question);

  //   const answer = async (element) => {
  //     if (element?.display) {
  //       element[`label_${target}`] = convertedElementObj?.[element.element_id]
  //         ? convertedElementObj?.[element.element_id]
  //         : element.label;
  //     }

  //     if (element.options.length) {
  //       for (const option of element.options) {
  //         for (const subElement of option.elements) {
  //           await answer(subElement);
  //         }
  //       }
  //     }
  //   };
  //   for (const section of elementsData?.sections) {
  //     if (section?.is_hidden) continue;
  //     section[`section_label_${target}`] = convertedSectionObj?.[section.section_id]
  //       ? convertedSectionObj?.[section.section_id]
  //       : section.section_label;
  //     for (const group of section?.groups) {
  //       if (group.is_hidden) continue;
  //       group[`group_label_${target}`] = convertedGroupObj?.[group.group_id]
  //         ? convertedGroupObj?.[group.group_id]
  //         : group.group_label;

  //       for (const element of group.elements) {
  //         await answer(element);
  //       }
  //     }
  //   }

  //   const res = await verifyElement(
  //     code,
  //     {
  //       cached_questions_json: {
  //         question: _.cloneDeep(elementsData),
  //       },
  //       is_completed: false,
  //     },
  //     authData?.[code]?.token
  //   );
  //   if (res?.success) {
  //     await getQuestionData(code);
  //     toast.success(res?.message);
  //   } else {
  //     toast.error(res?.message);
  //   }
  // };

  const handleContinue = async () => {
    await setStepperDetails(true, true, currentJumpIndex);
    setCurrentJumpIndex(null);
  };

  const handleBankSectionContinue = () => {
    dispatch(addCurrentStep({ tx_id: code, step: activeSection + 1 }));
    setActiveSection((prev) => prev + 1);
    setBankAccountVerificationModal(false);
  };

  return (
    <div
      className='form-layout'
      style={{
        padding: `0vh ${paddingDetails?.padding ? paddingDetails?.padding : 2}vw`,
      }}
    >
      <Helmet>
        <title>Questions Form</title>
      </Helmet>
      {loading && <Loader />}
      {/* <Header /> */}
      <StepValidationModal
        open={stepValidationModalOpen}
        setOpen={setStepValidationModalOpen}
        handleContinue={handleContinue}
        validationMessage={validationMessage}
        isBankStep={isBankStep}
      />
      <BankAccountVerificationModal
        open={bankAccVerificationModal}
        setOpen={setBankAccountVerificationModal}
        error={bankError}
        handleContinue={handleBankSectionContinue}
      />
      <Box variant='div' component='div' className='steps-wrapper-main mt-0'>
        <Container fixed>
          <Box variant='div' component='div' className='steps-wrapper'></Box>
        </Container>
        {/* <Container> */}
        <Grid container spacing={'10px'} style={{ marginTop: '0px' }}>
          <Grid xs={12} md={2.5} item={true}>
            <StepsHeader
              activeStep={activeSection}
              setActiveSection={setActiveSection}
              steps={languageForLabel && languageForLabel !== 'en' ? translatedSections : sections}
              stepChange={stepChange}
              logoUrl={companyDetails?.company_logo_url}
              hideSignature={authData?.[code]?.is_bank_account_client}
            />
          </Grid>
          <Grid xs={12} md={7} item={true}>
            {question?.sections
              ?.filter((section) => !section?.is_hidden && (!(
                isAdditionalParticipant &&
                authData?.[code]?.participant_type_name === "Buyer" &&
                section.section_label === "Disbursement of Funds"
              )))
              ?.map((section, sectionIdx) => {
                if (section.section_label == '') return;
                if (!section || sectionIdx !== activeSection) return;
                return (
                  <StepDetails
                    key={sectionIdx}
                    getQuestionData={getQuestionData}
                    token={authData?.[code]?.token}
                    authData={authData?.[code]}
                    section={section}
                    sections={sections}
                    sectionIdx={sectionIdx}
                    sectionCount={sections.length}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                    setStepperDetails={setStepperDetails}
                    signature={signature}
                    spouseSignature={spouseSignature}
                    sigDataURL={sigDataURL}
                    setSigDataURL={setSigDataURL}
                    companyDetails={companyDetails}
                    // branding={companyDetails?.branding}
                    // companyName={companyDetails?.company_name}
                    // address={companyDetails?.address}
                    orderId={orderInfo?.order_id}
                    checkNextStep={checkNextStep}
                    isShowAlertMessage={isShowAlertMessage}
                    isShowSubmissionPage={isShowSubmissionPage}
                    setShowSubmissionPage={setShowSubmissionPage}
                    setLoading={setLoading}
                    getSignatureData={getSignatureData}
                    disabled={isDisableForm}
                    paddingDetails={paddingDetails}
                    orderInfo={orderInfo}
                    // below static logic is for PHH
                    oneStepForm={authData?.[code]?.is_bank_account_client}
                    saveFormDataPHH={saveFormDataPHH}
                  />
                );
              })}
            {activeSection == sections?.length ? (
              <LastStep
                key={sections?.length}
                getQuestionData={getQuestionData}
                token={authData?.[code]?.token}
                authData={authData?.[code]}
                sectionIdx={sections?.length}
                sectionCount={sections?.length}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                setStepperDetails={setStepperDetails}
                signature={signature}
                spouseSignature={spouseSignature}
                sigDataURL={sigDataURL}
                setSigDataURL={setSigDataURL}
                companyDetails={companyDetails}
                // branding={companyDetails?.branding}
                // companyName={companyDetails?.name}
                // address={companyDetails?.address}
                orderId={orderInfo?.order_id}
                checkNextStep={checkNextStep}
                isShowAlertMessage={isShowAlertMessage}
                isShowSubmissionPage={isShowSubmissionPage}
                setShowSubmissionPage={setShowSubmissionPage}
                setLoading={setLoading}
                getSignatureData={getSignatureData}
                disabled={isDisableForm}
                documents={documents}
                paddingDetails={paddingDetails}
                sections={sections}
              />
            ) : (
              ''
            )}
          </Grid>
          <Grid xs={12} md={2.5} item={true}>
            <SideBar
              contents={orderInfo}
              companyName={companyDetails?.company_name}
              translate={translate}
            />
          </Grid>
        </Grid>
        {/* </Container> */}
      </Box>
    </div>
  );
};

export default Home;
