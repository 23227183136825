import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, FormControl, Autocomplete, TextField, Tooltip } from '@mui/material';
import DocumentField from '../index.jsx';
import {
  BANK_CITY_NAME,
  BANK_NAME_NAME,
  BANK_STATE_NAME,
  BUYER_DL_STATE_FIELD,
  DL_FIELD_STATE,
  DL_STATE_FIELD,
  FIELDS_TO_DISABLE,
} from '../../../../utils/constants';
import useRedux from '../../../../utils/hooks/useRedux';
import { areAllElementsSame, errorChecker, fetchSourceData } from '../../../../utils/helpers.js';
import ValueSelectionModal from '../../../questions/home/ValueSelectionModal.jsx';
import Loader from '../../Loader.jsx';
import { Help } from '@mui/icons-material';
const menuItemStyle = {
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: '5%',
  fontSize: 14,
  width: '95%',
  overflow: 'hidden',
  whiteSpace: 'unset',
  minHeight: '30px',
  cursor: 'pointer',
};

const SelectBox = ({ element, orderId, disabled, saveFormDataPHH }) => {
  const { code, participantNo } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const isDisableForm = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.is_pdf_generated;
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];
  const authData = useSelector((state) => state?.authSlice)?.[code];
  const isBuyer = authData?.participant_type_name?.toLowerCase().includes('buyer');
  const { setFormValue, getFormValue, getErrorDetails, removeError, setError, setTouched } =
    useRedux();
  const formValue = getFormValue(element.element_id);
  const { error, error_type } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [value, setValue] = useState('');
  const [sourceList, setSourceList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dataVal =
    formValue !== undefined ? formValue : element.user_value ? element.user_value : '';

  const hiddenFields = [BANK_NAME_NAME, BANK_CITY_NAME, BANK_STATE_NAME];
  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;
  const stateFieldID = (isBuyer ? BUYER_DL_STATE_FIELD : DL_STATE_FIELD)
  useEffect(() => {
    setSourceList(fetchSourceData(element));
  }, [element]);

  useEffect(() => {
    if (
      (sourceList?.length === 1 || areAllElementsSame(sourceList.map((source) => source?.value))) &&
      !element.user_value
    ) {
      if (element?.options?.map((opt) => opt.option_name)?.includes(sourceList[0]?.value)) {
        setValue(sourceList[0]?.value);
        setFormValue({ id: element.element_id, value: sourceList[0]?.value });
      }
    }
  }, [sourceList]);

  useEffect(() => {
    if (formValue !== undefined) setValue(formValue);
    else if (element.user_value) setValue(element.user_value);
  }, [formValue]);

  // for populating DL State
  useEffect(() => {
    if (
      !isAdditionalParticipant &&
      element.element_id === stateFieldID &&
      dlDetails?.state &&
      dlDetails?.transaction_response?.Approved
    ) {
      if (!dataVal) {
        setFormValue({
          id: element.element_id,
          value: dlDetails?.state,
        });
        setValue(dlDetails?.state);
        removeError(element.element_id);
        setTouched(element.element_id);
      }
    }
    // populating DL state for additional participant
    if (
      isAdditionalParticipant &&
      +participantNo === +element?.participant_no &&
      element?.element_name?.startsWith(
        authData?.participant_type_name?.toLowerCase() + DL_FIELD_STATE
      ) &&
      dlDetails?.state &&
      dlDetails?.transaction_response?.Approved
    ) {
      if (!dataVal) {
        setFormValue({
          id: element.element_id,
          value: dlDetails?.state,
        });
        setValue(dlDetails?.state);
        removeError(element.element_id);
        setTouched(element.element_id);
      }
    }
  }, [element?.element_id, dlDetails, dataVal]);

  const handleError = async (value) => {
    setIsLoading(true);
    const { isError, message } = errorChecker(element, value);
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
    setIsLoading(false);
  };

  const onSelect = (value) => {
    setTouched(element.element_id);
    setValue(value);
    setFormValue({ id: element.element_id, value: value });
    handleError(value);
    setOpen(false);
  };

  if (
    (element.element_id === stateFieldID ||
      element.element_name.startsWith(
        authData?.participant_type_name?.toLowerCase() + DL_FIELD_STATE
      )) &&
    dlDetails?.transaction_response?.Approved &&
    dlDetails?.transaction_response?.IdentificationTypeId &&
    dlDetails?.transaction_response?.IdentificationTypeId !== 22
  ) {
    return;
  }

  if (hiddenFields?.includes(element.label?.replace(':', '')) && !element.value_from_bank_source) {
    return;
  }

  const isDisabledField =
    FIELDS_TO_DISABLE?.includes(element.label.replace(':', '')) &&
    element.value_from_bank_source &&
    element.value_from_bank_source !== 'no';
  return (
    <>
      {isLoading && <Loader />}

      <Grid xs={12} md={6} spacing={1} className='grid-container plt-8'>
        <Grid xs={12} md={12} item={true}>
          <label
            dangerouslySetInnerHTML={{
              __html: languageForLabel
                ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  : element?.label
                : element?.label,
            }}
          />
          <label className='required'>{element.required && '*'}</label>{' '}
          {element?.help_note ? (
            <Tooltip title={element?.help_note}>
              {' '}
              <Help className='f-14 mt-minus-2 cursor-pointer' />
            </Tooltip>
          ) : (
            ''
          )}
        </Grid>
        <Grid xs={12} md={12} item={true} className='mt-4 t-field'>
          <div className='d-flex align-items-center'>
            <FormControl
              error={error && error_type !== 'warning'}
              className='full-width question-select-field'
            >
              <Autocomplete
                className='full-width question-select-field'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={
                  value
                    ? element?.options?.find(
                        (option) => option.option_name?.toLowerCase() === value?.toLowerCase()
                      )
                    : null
                }
                getOptionLabel={(option) => {
                  if (!option) return '';
                  return languageForLabel
                    ? translations?.[languageForLabel]?.options?.[option.option_id] ||
                        option.option_name
                    : option.option_name;
                }}
                disablePortal
                renderOption={(props, option) => {
                  return (
                    <div
                      {...props}
                      style={menuItemStyle}
                      className={value === option?.option_name ? 'bg-selected' : ''}
                    >
                      {languageForLabel
                        ? translations?.[languageForLabel]?.options?.[option.option_id]
                          ? translations?.[languageForLabel]?.options?.[option.option_id]
                          : option?.option_name
                        : option?.option_name}
                    </div>
                  );
                }}
                onBlur={() => {
                  handleError(value);
                }}
                disabled={disabled || isDisabledField || element?.disabled}
                options={element.options}
                onChange={(_, value) => {
                  onSelect(value?.option_name);
                }}
                disableClearable
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      name={element?.element_name}
                      size='small'
                      error={error}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      InputLabelProps={
                        disabled
                          ? { shrink: true, ...params.InputLabelProps }
                          : { ...params.InputLabelProps }
                      }
                    />
                  );
                }}
              ></Autocomplete>
            </FormControl>
          </div>
        </Grid>
      </Grid>

      {element.options &&
        element.options.map(
          (option, idx) =>
            option.option_name == value && (
              <React.Fragment key={idx}>
                {option.elements.map((element, index) => {
                  return (
                    <DocumentField
                      key={index}
                      element={element}
                      orderId={orderId}
                      disabled={isDisableForm}
                      saveFormDataPHH={saveFormDataPHH}
                    />
                  );
                })}
              </React.Fragment>
            )
        )}
      <ValueSelectionModal
        setOpen={setOpen}
        open={open}
        data={sourceList}
        onSelect={onSelect}
        value={value}
      />
    </>
  );
};

export default SelectBox;
